<template>
    <div>
        <v-app-bar flat tag="div" color="bg" :height="( $route.meta.title == 'home' ? '75' : '110')" :class="'px-6 py-2 '+( $route.meta.title == 'home' ? 'bg-orange-reverse' : '')" id="subHeader" ref="subHeader" style="position:sticky">
            <div class="subheader-content" >
                <div>
                    <h1 class="mt-0 d-flex align-center" v-if="title">
                        <v-btn color="pink" @click="simulatePrevious" small depressed v-if="previousBtn">
                            <v-icon small color="white">$prev</v-icon>
                            <span class="white--text ml-2">{{ $t('previous') }}</span>
                        </v-btn>
                        <div v-if="previousBtn" class="dividerPrevious mx-4 ml-5"></div>
                        <v-avatar class="white mr-4" size="55" v-if="$route.meta.title == 'home'">
                            <v-avatar  size="55">
                                <v-img
                                    width="55"
                                    cover
                                    :src="
                                        api +
                                        '/user/' +
                                        $store.state.auth.user.id+
                                        '/getAvatar?token=' +
                                        $store.state.auth.idToken
                                    "   
                                ></v-img>
                            </v-avatar>
                        </v-avatar>
                        <v-chip small :color="color ? color : 'primary'" outlined v-show="chips != ''"  v-else-if="$route.meta.title != 'home' && chips !=''" class="mr-3">{{chips }}</v-chip>
                        <span :class="( $route.meta.title == 'home' ? 'white--text' : '')">
                            <h1 :class="( $route.meta.title == 'home' ? 'white--text mb-0' : 'mb-0')">{{ title }}</h1>
                            <div class="mt-2" style="font-weight:normal;" v-if="packId != 2 && $route.meta.title == 'home' && $func.hasRight('profile/is_marketing')">{{ $t('marketingSubtitleText') }}</div>
                            <div class="mt-2" style="font-weight:normal;" v-if="packId != 2 && $route.meta.title == 'home' && !$func.hasRight('profile/is_marketing')">{{ $t('managerVendorSubtitleText') }}</div>
                        </span>
                        <v-chip small :color="$func.getChipColorByStatus(statusChips)"  v-show="statusChips != ''" v-if="statusChips !=''" class="ml-3 white--text">{{ $t(statusChips) }}</v-chip>
                    </h1>
                    <!-- <BreadCrumb /> -->
                </div>
            </div>
            <v-spacer></v-spacer>
            <div>
                <SubAction v-if="subActions" :subActions="subActions" v-on="$listeners"/>
            </div>
        </v-app-bar>
        <SubMenu v-if="subRoutes && subRoutes.length > 1 && subRoutesAccess" :subRoutes="subRoutes" class="subMenu"/>
    </div>
</template>

<script>
import SubMenu from '@/components/SubMenu'
import SubAction from '@/components/SubAction'
import BreadCrumb from '@/components/BreadCrumb'
import GenericDataService from '@/services/GenericDataService'
export default {
    name:"SubHeader",
    components: {
        SubMenu,
        SubAction,
        BreadCrumb
    },
    data() {
        return {
            from:{},
            api: process.env.VUE_APP_API_URL,
            subRoutesAccess: false,
        }
    },
    computed: {
        packId(){
            if(this.$store.state.auth.currentUser.accountParameters.packId)
                return this.$store.state.auth.currentUser.accountParameters.packId
            else 
                return false
        },
        subRoutes: function() {
            return this.$store.state.base.submenu
        },
        subActions: function() {
            return this.$store.state.base.headerActions
        },
        title: function(){
            if(this.$route.meta.title == "userProfile"){
                return (this.$store.state.user.user.firstname ? this.$store.state.user.user.firstname : "")+" "+(this.$store.state.user.user.name ? this.$store.state.user.user.name : "")
            } else if(this.$route.meta.title == "contactDetails") {
                return (this.$store.state.contact.contact.firstname ? this.$store.state.contact.contact.firstname : "")+" "+(this.$store.state.contact.contact.name ? this.$store.state.contact.contact.name : "")+" "+(this.$store.state.contact.contact.company ? this.$store.state.contact.contact.company : "")
            }  else if(this.$route.meta.title == "home") {
                return this.$t("Welcome")+" "+this.$store.state.auth.user.firstname+" "+this.$store.state.auth.user.name
            } else if(this.$route.meta.title == "entityDetail"){
                return this.$store.state.entities.entity.social_raison
            } else if(['CampaignOutPDetail', 'CampaignOutPDetailDashboard', 'CampaignOutPDetailTemplate', 'CampaignOutPDetailRecipient', 'CampaignOutPDetailRecipientList', 'CampaignOutPDetailRecipientDetail', 'CampaignOutPDetailLead', 'CampaignOutPBudget'].includes(this.$route.meta.title)){
                return this.$store.state.campaign.campaign.name;
            } else if(['CampaignInDetail', 'CampaignInDetailDashboard', 'CampaignInDetailLead', 'CampaignInBudget'].includes(this.$route.meta.title)){
                return this.$store.state.campaign.campaign.name;
            } else if(['CampaignCallDetail', 'CampaignCallDetailDashboard', 'CampaignCallDetailRecipient', 'CampaignCallDetailRecipientList', 'CampaignCallDetailRecipientDetail', 'CampaignCallDetailLead'].includes(this.$route.meta.title)){
                return this.$store.state.campaign.campaign.name;
            } else if(['OperationCampaigns', 'OperationCampaignDetail', 'OperationCampaignDetailDashboard', 'OperationCampaignDetailTemplate', 'OperationCampaignDetailRecipient', 'OperationCampaignDetailLead', 'OperationCampaignBudget'].includes(this.$route.meta.title)){
                return this.$store.state.campaign.campaign.name;
            } else if(['retroplanningDetails'].includes(this.$route.meta.title)){
                return this.$store.state.campaign.campaign.name;
            } else if(this.$route.meta.title == "OperationsDetailStats" || this.$route.meta.title == "OperationsDetailRessource" || this.$route.meta.title == "OperationsDetailTeam" || this.$route.meta.title == "OperationsDetailCampaignList" || this.$route.meta.title == "OperationsDetailLead" || this.$route.meta.title == "WorkflowOperationList" || this.$route.meta.title == "OperationsDetailPlanning" || this.$route.meta.title == "OperationsDetailPassage" || this.$route.meta.title == "OperationsContactDetail" || this.$route.meta.title == "operationsDetailDashboard" || this.$route.meta.title == "OperationsDetailContactList" || this.$route.meta.title == "OperationsDetailContacts" || this.$route.meta.title == "operationDetailRegistration" || this.$route.meta.title == "OperationsDetailOpportunity"){
                return this.$store.state.operation.operations.name;
            } else if(this.$route.meta.title == "OperationsDetailReport"){
                return this.$t("end_of_mission_report") + " (" + this.$store.state.operation.operations.name + ")";
            } else if(this.$route.meta.title == "brandDetails" || this.$route.meta.title == "BrandDashboard" || this.$route.meta.title == "BrandStyle"){
                return this.$store.state.brand.brand.libelle;
            } else if(this.$route.meta.title == "missionDetail" || this.$route.meta.title == "missionStakeholders" || this.$route.meta.title == "MissionDetailContactList" || this.$route.meta.title == "MissionContactDetail"){
                return this.$store.state.missions.mission.name;
            } else if(this.$route.meta.title == "workflowDetails"){
                if(this.$store.state.workflowName.includes('_')){
                     return this.$t(this.$store.state.workflowName);
                } else {
                     return this.$store.state.workflowName;
                }
            } else if(this.$route.meta.title == "OperationsTypeDetailConfiguration" || this.$route.meta.title == "OperationsTypeDetailRetroplanning" || this.$route.meta.title == "OperationsTypeDetailWorkflows" || this.$route.meta.title == "OperationsTypeDetailMedias"){
                return this.$store.state.operationTypeName;
            } else if(this.$route.meta.title == "WebsiteDashboard") {
                return this.$store.state.website.website.name;
            } else {
                return this.$t(this.$route.meta.title)
            }
        },
        chips:function(){
            // if(this.$store.state.contact.contact.__link && this.$route.meta.title == "contactDetails"){
            //     return this.$store.state.contact.contact.__link.ref
            // } else 
            if(['CampaignOutPDetail', 'CampaignOutPDetailDashboard', 'CampaignOutPDetailTemplate', 'CampaignOutPDetailRecipient', 'CampaignOutPDetailRecipientList', 'CampaignOutPDetailRecipientDetail', 'CampaignOutPDetailLead', 'CampaignOutPBudget'].includes(this.$route.meta.title) && this.$store.state.campaign.campaign.__link){
                return this.$store.state.campaign.campaign.__link.ref
            } else if(['CampaignInDetail', 'CampaignInDetailDashboard', 'CampaignInDetailLead', 'CampaignInBudget'].includes(this.$route.meta.title) && this.$store.state.campaign.campaign.__link){
                return this.$store.state.campaign.campaign.__link.ref
            } else if(['CampaignCallDetail', 'CampaignCallDetailDashboard', 'CampaignCallDetailRecipient', 'CampaignCallDetailRecipientList', 'CampaignCallDetailRecipientDetail', 'CampaignCallDetailLead'].includes(this.$route.meta.title) && this.$store.state.campaign.campaign.__link){
                return this.$store.state.campaign.campaign.__link.ref
            // } else if((this.$route.meta.title == "OperationsDetailStats" || this.$route.meta.title == "OperationsDetailTeam" || this.$route.meta.title == "OperationsDetailCampaignList" || this.$route.meta.title == "OperationsDetailLead" || this.$route.meta.title == "WorkflowOperationList" || this.$route.meta.title == "OperationsDetailPlanning" || this.$route.meta.title == "OperationsDetailPassage" || this.$route.meta.title == "OperationsContactDetail" || this.$route.meta.title == "operationsDetailDashboard" || this.$route.meta.title == "OperationsDetailContactList" || this.$route.meta.title == "OperationsDetailContacts" || this.$route.meta.title == "operationDetailRegistration") && this.$store.state.operation.operations.__link){
            //     return this.$store.state.operation.operations.__link.ref
            // } else if(['OperationCampaigns', 'OperationCampaignDetail', 'OperationCampaignDetailDashboard', 'OperationCampaignDetailTemplate', 'OperationCampaignDetailRecipient', 'OperationCampaignDetailLead', 'OperationCampaignBudget'].includes(this.$route.meta.title)){
            //     return this.$store.state.campaign.campaign.__link.ref
            // } else if(this.$route.meta.title == "brandDetails" || this.$route.meta.title == "BrandDashboard" || this.$route.meta.title == "BrandStyle"){
            //     return this.$store.state.brand.brand.type.label;
            } else if(this.$route.meta.title == "missionDetail" || this.$route.meta.title == "missionStakeholders" || this.$route.meta.title == "MissionDetailContactList" || this.$route.meta.title == "MissionContactDetail"){
                return this.$store.state.missions.mission.__link.ref;
            }else if (this.$route.meta.title == "home"){
                return this.$store.state.auth.user.avatar ? this.$store.state.auth.user.avatar.url : "dede"
            } else {
                return ""
            }
        },
        statusChips:function(){
            if(['CampaignOutPDetail', 'CampaignOutPDetailDashboard', 'CampaignOutPDetailTemplate', 'CampaignOutPDetailRecipient', 'CampaignOutPDetailRecipientList', 'CampaignOutPDetailRecipientDetail', 'CampaignOutPDetailLead', 'CampaignOutPBudget'].includes(this.$route.meta.title) && this.$store.state.campaign.campaign.status){
                return this.$store.state.campaign.campaign.status;
            } else if(['CampaignInDetail', 'CampaignInDetailDashboard', 'CampaignInDetailLead', 'CampaignInBudget'].includes(this.$route.meta.title) && this.$store.state.campaign.campaign.status){
                return this.$store.state.campaign.campaign.status;
            } else if(['CampaignCallDetail', 'CampaignCallDetailDashboard', 'CampaignCallDetailRecipient', 'CampaignCallDetailRecipientList', 'CampaignCallDetailRecipientDetail', 'CampaignCallDetailLead'].includes(this.$route.meta.title) && this.$store.state.campaign.campaign.status){
                return this.$store.state.campaign.campaign.status;
            } else if(['OperationsDetailStats', 'OperationsDetailTeam', 'OperationsDetailCampaignList','OperationsDetailOpportunity', 'OperationsDetailLead', 'WorkflowOperationList', 'OperationsDetailPlanning', 'OperationsDetailPassage', 'OperationsContactDetail', 'operationsDetailDashboard', 'OperationsDetailContactList', 'OperationsDetailContacts', 'operationDetailRegistration'].includes(this.$route.meta.title) && this.$store.state.operation.operations.status){
                return this.$store.state.operation.operations.status;
            } else if(['OperationsDetailReport'].includes(this.$route.meta.title) && this.$store.state.operation.operations.status){
                return this.$store.state.operation.operations.report_status;
            } else if(['OperationCampaigns', 'OperationCampaignDetail', 'OperationCampaignDetailDashboard', 'OperationCampaignDetailTemplate', 'OperationCampaignDetailRecipient', 'OperationCampaignDetailLead', 'OperationCampaignBudget'].includes(this.$route.meta.title)){
                return this.$store.state.campaign.campaign.status;
            } else if(['retroplanningDetails'].includes(this.$route.meta.title)){
                return this.$store.state.campaign.campaign.status;
            } else if(['missionDetail', 'missionStakeholders', 'MissionDetailContactList', 'MissionContactDetail'].includes(this.$route.meta.title) && this.$store.state.missions.mission.status){
                return this.$store.state.missions.mission.status;
            } else {
                return "";
            }
        },
        color:function(){
            if(this.$route.meta.title == "brandDetails" || this.$route.meta.title == "BrandDashboard" || this.$route.meta.title == "BrandStyle"){
                return this.$store.state.brand.brand.type.color;
            }
        },
        previousBtn: function(){
            if((["MissionDashboard", 'OperationsDetailDashboard', "OperationsDetailContactList", "OperationsDetailLead", "OperationsDetailContacts", "WorkflowOperationList", "OperationsDetailTeam", "OperationsDetailPlanning", "OperationsDetailStats", "OperationsDetailTodoList",
            'OperationCampaignDetailDashboard', "OperationCampaignDetailTemplate", "OperationCampaignDetailRecipient", "OperationCampaignDetailLead", "OperationCampaignBudget",
            'RetroplanningDetail',
            'OperationsTypeDetailConfiguration', "OperationsTypeDetailRetroplanning", "WorkflowOperationTypeList",
            'EntityDashboard', "EntityStaff", "EntityOperation", "EntityContactList", "ContactDetail", "BrandDashboard", "UserDetail", "OperationsContactDetail",
            "OperationsDetailOpportunity", "OperationsDetailReport"]).includes(this.$route.name)){
                return true;
            }
            return false;
        },
        
    },
    methods: {
        simulatePrevious() {
            if (this.from.name && window.history.state) {
                this.$router.go(-1)
            } else {
                if ((['OperationsDetailDashboard', "OperationsDetailContactList", "OperationsDetailLead", "OperationsDetailContacts", "WorkflowOperationList", "OperationsDetailTeam", "OperationsDetailPlanning", "OperationsDetailStats", "OperationsDetailTodoList", "OperationsContactDetail"]).includes(this.$route.name)) {
                    this.$router.push({ name: 'OperationsList' });
                } else if ((['OperationsTypeDetailConfiguration', "OperationsTypeDetailRetroplanning", "WorkflowOperationTypeList"]).includes(this.$route.name)) {
                    this.$router.push({ name: 'OperationsTypesList' });
                } else if ((['EntityDashboard', "EntityStaff", "EntityOperation", "EntityContactList"]).includes(this.$route.name)) {
                    this.$router.push({ name: 'EntityList' });
                } else if ((["ContactDetail"]).includes(this.$route.name)) {
                    if (this.$func.hasRight('lead/contactMenu')) {
                        this.$router.push({ name: 'ContactsList' });
                    } else {
                        this.$router.push({ name: 'Home' });
                    }
                } else if ((["BrandDashboard"]).includes(this.$route.name)) {
                    this.$router.push({ name: 'BrandList' });
                } else if ((["UserDetail"]).includes(this.$route.name)) {
                    this.$router.push({ name: 'UserList' });
                } else if ((["MissionDashboard"]).includes(this.$route.name)) {
                    this.$router.push({ name: 'MissionsList' });
                } else if ((['OperationCampaignDetailDashboard', "OperationCampaignDetailTemplate", "OperationCampaignDetailRecipient", "OperationCampaignDetailLead", "OperationCampaignBudget"]).includes(this.$route.name)) {
                    if (this.$route.params.operation_id) {
                        this.$router.push({name:'OperationDetail', params: {operation_id: this.$route.params.operation_id}});
                    } else {
                        this.$router.push({ name: 'OperationsList' });
                    }
                } else if ((["RetroplanningDetail"]).includes(this.$route.name)) {
                    this.$router.push({ name: 'retroplanningEmailList' });
                } else if ((["OperationsDetailReport"]).includes(this.$route.name)) {
                    this.$router.push({ name: 'Home' });
                }
            }
        }
    },
    watch: {
        subRoutes(val){
            let count = 0;
            if(val && val.length > 0){
                val.forEach(element => {
                    if(!element.rights || this.$func.hasRight(element.rights)){
                        count++;
                    }
                });
            }
            
            if(count > 1)
                this.subRoutesAccess = true;
        },
        $route(to, from) {
            this.from = from;
        },
        subRoutesAccess(val){
            if(!val)
                this.$emit('noSubMenu')
        }
    },
    created() {
        let count = 0;
        this.subRoutes.forEach(element => {
            if(!element.rights || this.$func.hasRight(element.rights)){
                count++;
            }
        });
        if(count > 1)
            this.subRoutesAccess = true;
    },
}
</script>
<style lang="scss">
    .indispoBtn, .preresaBtn {
        .v-btn__content {
            font-size:14px;
        }
    }
    #subHeader {
        .v-toolbar__content{
            padding-top:0;
            padding-bottom:0 !important;
            .subheader-content {
                height:100%;
                display:flex;
                flex-direction:column;
                align-items:flex-start;
                justify-content: center;
                color:#7a7879;
                h1 {
                    font-weight: 800;
                    font-size:24px;
                    line-height:18px;
                }
                p {
                    margin-bottom:0;
                    font-size:14px;
                    color: #b5b5b5;
                }
                &.no-menu {
                    justify-content: center;
                    h1 {
                        margin-top:0px;
                    }
                }
            }
        }
    }
    .subMenu {
        padding-left:24px;
        padding-right:24px;
        background-color:#FFF;
    }
    .dividerPrevious {
        height: 28px;
        width: 2px;
        border-left: 2px solid var(--v-primary-base);
    }
</style>
