import store from '../../store'
import GenericDataService from '@/services/GenericDataService'

export default [
    {
        path:'administration',
        name:'Administration',
        meta: { breadCrumb: "administration", titleDocument: "administration", title:'administration', subtitle:'accountSettings', rights: ["admin/managerole"] } ,
        redirect:{ name : 'Roles'},
        component: function () {
            return import(/* webpackChunkName: "contactsList" */ /* webpackPrefetch: true */'../../modules/administration/views/Administration.vue')
        },
        beforeEnter: (to, from, next) => {
            GenericDataService.getData('/menu/getsubmenu?module=administration').then((response) => {
                let submenu = response.data.data;
                store.dispatch('base/SET_SUB_MENU', submenu)
                next();
            })  
        },
        children: [
            {
                path:'roles-and-permissions',
                name:'Roles',
                meta: { breadCrumb: "rolesAndPermissions", titleDocument: "rolesAndPermissions", title:'rolesAndPermissions', subtitle:'accountSettings', rights: ["admin/managerole"] } ,
                component: function () {
                    return import(/* webpackChunkName: "administrationRole" */ '../../modules/administration/views/AdministrationRoles.vue')
                },
            },
            {
                path:'theme',
                name:'Theme',
                meta: { breadCrumb: "theme", titleDocument: "theme", title:'theme', subtitle:'accountSettings', rights: ["admin/managetheme"] } ,
                component: function () {
                    return import(/* webpackChunkName: "administrationTheme" */ '../../modules/administration/views/AdministrationTheme.vue')
                },
            },
            {
                path:'providers',
                name:'Providers',
                meta: { breadCrumb: "providers", titleDocument: "providers", title:'providers', subtitle:'accountSettings', rights: ["admin/manageprovider"] },
                component: function () {
                    return import(/* webpackChunkName: "administrationProviders" */ '../../modules/administration/views/AdministrationProviders.vue')
                },
            },
            {
                path:'custom-fields',
                name:'CustomFields',
                meta: { breadCrumb: "customFields", titleDocument: "customFields", title:'customFields', subtitle:'accountSettings', rights: ["admin/managecustomvalue"] } ,
                component: function () {
                    return import(/* webpackChunkName: "administrationCustom" */ '../../modules/administration/views/AdministrationCustomFields.vue')
                },
            },
            {
                path:'marques',
                meta: {breadCrumb: 'brand', titleDocument: "theBrands", rights: ["brand/add", "brand/update", "brand/delete"] },
                redirect:{ name: 'BrandList'},
                component: function () {
                    return import(/* webpackChunkName: "contactsList" */ /* webpackPrefetch: true */'../../modules/brand/views/Brand.vue')
                },
                
                children: [
                    {
                        path:'/',
                        name:'BrandList',
                        meta: { title:'BrandList', titleDocument: "theBrands", rights: ["brand/add", "brand/update", "brand/delete"] },
                        component: function () {
                            return import(/* webpackChunkName: "contactsList" */ /* webpackPrefetch: true */'../../modules/brand/views/BrandList.vue')
                        },
                        
                    },
                    {
                        path:':brand_id',
                        meta: { breadCrumb: "brandDetails", titleDocument: "brandDetails", title: "brandDetails", subMenu: false, rights: ["brand/add"] } ,
                        name: 'BrandDetail',
                        component: function () {
                            return import(/* webpackChunkName: "contactsDetail" */ /* webpackPrefetch: true */'../../modules/brand/views/BrandDetail.vue')
                        },
                        redirect:{name: 'BrandDashboard'},
                        children: [
                            {
                                path:'dashboard',
                                name:'BrandDashboard',
                                meta: { title:'BrandDashboard', titleDocument: "brandDetails", rights: ["brand/add", "brand/update", "brand/delete"] },
                                component: function () {
                                    return import(/* webpackChunkName: "contactsList" */ /* webpackPrefetch: true */'../../modules/brand/views/BrandDashboard.vue')
                                },
                            }
                        ]
                    },
                ]
            },
            {
                path:'entities',
                redirect:{name: 'EntityList'},
                meta: {breadCrumb:'listOfGroupsAndSellingPoints', titleDocument: "entityList", rights: ["entity/add", "entity/update", "entity/delete"] },
                component: function () {
                  return import(/* webpackChunkName: "entities" */ '../../modules/entities/views/Entity.vue')
                },
                children: [
                    {
                        path:'',
                        name:'EntityList',
                        meta: { title:'listOfGroupsAndSellingPoints', titleDocument: "entityList", rights: ["entity/menu"] },
                        component: function () {
                          return import(/* webpackChunkName: "entitiesList" */ '../../modules/entities/views/EntityList.vue')
                        },
                    },
                    {
                        path:':entity_id',
                        meta: { breadCrumb: "entityDetail", titleDocument: "entityDetail", disabled: true } ,
                        name: 'EntityDetail',
                        redirect:{name: 'EntityDashboard'},
                        beforeEnter: (to, from, next) => {
                            GenericDataService.getData('/menu/getsubmenu?module=entity').then((response) => {
                                let submenu = response.data.data;
                                store.dispatch('base/SET_SUB_MENU', submenu)
                                next();
                            })
                        },
                        component: function () {
                            return import(/* webpackChunkName: "entitiesDetail" */ '../../modules/entities/views/EntityDetail.vue')
                        },
                        children: [
                            {
                                path:'dashboard',
                                name:'EntityDashboard',
                                meta: { breadCrumb: "dashboard", titleDocument: "entityDashboard", title:"entityDetail", rights: ["entity/get"] } ,
                                component: function () {
                                  return import(/* webpackChunkName: "entitiesDashboard" */ '../../modules/entities/views/EntityDetailDashboard.vue')
                                },
                              },
                              {
                                path:'staff',
                                name:'EntityStaff',
                                meta: { breadCrumb: "staff", titleDocument: "entityStaff", title:"entityDetail", rights: ["user/add", "lead/update", "user/delete"] } ,
                                component: function () {
                                  return import(/* webpackChunkName: "entitiesStaff" */ '../../modules/entities/views/EntityDetailStaff.vue')
                                },
                              },
                              {
                                path:'campaigns',
                                name:'EntityCampaign',
                                meta: { breadCrumb: "campaigns", titleDocument: "entityCampaign", title:"entityDetail"} ,
                                component: function () {
                                  return import(/* webpackChunkName: "entitiesCampaign" */ '../../modules/entities/views/EntityDetailCampaign.vue')
                                },
                              },
                              {
                                path:'operations',
                                name:'EntityOperation',
                                meta: { breadCrumb: "operations", titleDocument: "entityOperation", title:"entityDetail"} ,
                                component: function () {
                                  return import(/* webpackChunkName: "entitiesOperation" */ '../../modules/entities/views/EntityDetailOperation.vue')
                                },
                              },
                              {
                                path:'contacts',
                                name:'EntityContact',
                                meta: { breadCrumb: "contacts", titleDocument: "entityContact", title:"entityDetail", rights: ["lead/add", "lead/update", "lead/get"] } ,
                                redirect: {name: 'EntityContactList'},
                                component: function () {
                                    return import(/* webpackChunkName: "contactsList" */ /* webpackPrefetch: true */'../../modules/base/views/BaseEmpty.vue')
                                },
                                children: [
                                  {
                                    path:'',
                                    name:'EntityContactList',
                                    meta: { title:'entityDetail', titleDocument: "entityContact", rights: ["lead/add", "lead/update", "lead/get"] },
                                    component: function () {
                                        return import(/* webpackChunkName: "contactsList" */ /* webpackPrefetch: true */'../../modules/entities/views/EntityDetailContact.vue')
                                    },
                                  },
                                  {
                                    path:':contact_id',
                                    meta: { breadCrumb: "contactDetails", titleDocument: "entityContactDetail", title: "contactDetails", subMenu: false, rights: ["lead/get"] } ,
                                    name: 'EntityContactDetail',
                                    component: function () {
                                        return import(/* webpackChunkName: "contactsDetail" */ /* webpackPrefetch: true */'../../modules/contacts/views/ContactDetail.vue')
                                    }
                                  },
                                ]
                              },
                              {
                                path:'stock',
                                name:'EntityStock',
                                meta: { breadCrumb: "stock", titleDocument: "entityStock", title:"entityDetail"} ,
                                component: function () {
                                  return import(/* webpackChunkName: "entitiesStock" */ '../../modules/entities/views/EntityDetailStock.vue')
                                },
                              },
                        ]
                    },
                ]
            },
            {
                path:'users',
                redirect:{name: 'UsersList'},
                meta: { title:'userAccounts', titleDocument: "usersList", breadCrumb: "usersList", rights: ["user/add", "user/update", "user/delete", "user/advanced"] },
                component: function () {
                  return import(/* webpackChunkName: "users" */ '../../modules/users/views/User.vue')
                },
                children: [
                    {
                        path:'',
                        name:'UsersList',
                        meta: { title:'userAccounts', titleDocument: "userAccounts", rights: ["user/add", "user/update", "user/delete", "user/advanced"] },
                        component: function () {
                            return import(/* webpackChunkName: "usersList" */ '../../modules/users/views/UserList.vue')
                        },
                    },
                    {
                        path:':user_id',
                        meta: { breadCrumb: "userProfile", titleDocument: "userProfile", title:"userProfile", rights: ["user/get"] } ,
                        name: 'UserDetail',
                        component: function () {
                          return import(/* webpackChunkName: "usersDetail" */ '../../modules/users/views/UserDetail.vue')
                        }
                    },
                ]
              },
              {
                path:'groupsOfUsers',
                name:'GroupsOfUsersList',
                meta: { title:'groupsOfUsers', titleDocument: "groupsOfUsersList", breadCrumb: "groupsOfUsersList", rights: ["user/add", "user/update", "user/delete", "user/advanced"] },
                component: function () {
                    return import(/* webpackChunkName: "groupsOfUsersList" */ '../../modules/groupsofusers/views/GroupsOfUsersList.vue')
                }
            },
            {
                path:'mediatheque',
                name:'Mediatheque',
                meta: { title:'mediatheque', titleDocument: "mediatheque", breadCrumb: "mediatheque", rights: ["admin/managemedia"] },
                component: function () {
                    return import(/* webpackChunkName: "groupsOfUsersList" */ '../../modules/mediatheque/views/Mediatheque.vue')
                }
            }
        ]
    },
]