<template>
    <div>
        <v-container fluid class="pa-6 ">
            <v-row>
                <v-col v-for="app in apps" :key="app.id" cols="3" @click="openDetails(app)">
                    <v-card rounded="lg" class="hoverApp">
                        <v-card-text>
                            <span v-if="app.activeApp" class="iconApp">
                                <v-chip color="success" v-if="app.activeApp.token">
                                    Activé
                                    <v-icon dense small right>
                                        $check_l
                                    </v-icon>
                                </v-chip>
                                <v-icon color="warning" v-else>$settings_s</v-icon>
                            </span>
                            <v-img :src="app.logo" :alt="app.name" class="logoApps"></v-img>
                            <h5 class="mt-4"><b>{{ app.name }}</b></h5>
                            <p class="truncateText">{{ app.resume }}
                            </p>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
        </v-container>
        <AppListDialog @cancel="dialogDes = false" :dialog="dialogDes" :appDetails="appSelected" />
    </div>
</template>

<script>
import AppListDialog from '../applicationsComponents/appListDialog.vue';
import GenericDataService from '../../../services/GenericDataService';

export default {
    name: 'ApplicationList',
    components: { AppListDialog },
    data() {
        return {
            dialogDes: false,
            apps: [],
            appSelected: {}
        }
    },
    mounted() {
        // Appel API pour récupérer la liste des applications disponibles
        GenericDataService.getData('/externalapp/getExternalApps').then((response) => {
            this.apps = response.data.data
        })
        // Appel API pour récupérer la liste des applications connectés
    },
    methods: {
        openDetails(app) {
            this.appSelected = app
            this.dialogDes = true
        }
    }
}
</script>

<style lang="scss">
.logoApps {
    width: 50px;
    margin-bottom: 30px;
    border-radius: 50px;
}

.hoverApp {
    cursor: pointer;
    height: 180px;

    .iconApp {
        position: absolute;
        right: 15px;
    }
}

.truncateText {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}
</style>