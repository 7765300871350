
const state = () => ({
    campaignsout: [],
    campaignsin: [],
    campaign: {},
    todos: {},
    reaffectVendorDialog: false,
    reaffectVendorRp: null,
    programRpDialog: false,
})

const mutations = {
    setCampaignsData(state, value){
        state.campaigns = value;
    },
    setCampaignsOutData(state, value){
        state.campaignsout = value;
    },
    setCampaignsInData(state, value){
        state.campaignsin = value;
    },
    setCampaignData(state, value){
        state.campaign = Object.assign({}, state.campaign, value)
    },
    removeSingleCampaignData(state){
        state.campaign = {};
    },
    addCampaignData(state, value){
        state.campaigns.push(value);
    },
    removeCampaignData(state, value){
        state.campaigns = state.campaigns.filter((e) => e.id !== value.deleted);
    },
    setTodosData(state, value){
        state.todos = value;
    },
    setReaffectVendorDialog(state, value){
        state.reaffectVendorDialog = value;
    },
    setReaffectVendorRp(state, value){
        state.reaffectVendorRp = value;
    },
    setProgramRpDialog(state, value){
        state.programRpDialog = value;
    }
}

const actions = {
    SET_REAFFECT_VENDOR({ commit }, value) {
        commit("setReaffectVendorDialog", true);
        commit("setReaffectVendorRp", value);
    },
    CLOSE_REAFFECT_VENDOR({commit}, value){
        commit("setReaffectVendorDialog", false);
        commit("setReaffectVendorRp", null);
    },
    SET_PROGRAM_RP_DIALOG({ commit }, value) {
        commit("setProgramRpDialog", value)
    },
}

const getters = {
    
}

export default {
    namespaced: true,
    state,
    actions, 
    mutations,
    getters
}