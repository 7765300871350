<template>
    <div class="errorPage primary">
        <div>
            <v-img  :width="300" height="150" contain src="https://cdn.dev.media.upyourbizz.com/data/DEMO/72/8b/68/22010e4b13-164991617646.svg"></v-img>
        </div>
        <h1 class="mb-8 white--text">Compte Désactivé</h1>
    </div>
</template>
<script>
export default {
    name:"Error427"
}
</script>
<style lang="scss">
    .errorPage {
        height:100%;
        display:flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
</style>