export default {
    methods: {
        validate() {
            if(this.field.rules) {
                for (let i in this.field.rules) {
                    let rule = this.field.rules[i];
                    let response = this[rule.fonction](this.model[this.fieldName], ...rule.params);
                    if(response !== true) {
                        return response;
                    }
                }
            }
            
            return true;
        },
        required(value) {
            if((!value || value.length === 0 || value === false) && value !== 0) {
                return this.$t('requiredField');
            }

            return true;
        },
        length(value, min = null, max = null) {
            if(value && min !== null && value.length < min) {
                return this.$t('minLengthField', { nb: min });
            }

            if(value && max !== null && value.length > max) {
                return this.$t('maxLengthField', { nb: max });
            }

            return true;
        },
        email(value) {
            const regex = /^(?!.\.\.)[a-zA-Z0-9.!#$%&'+/=?^_`{|}~-]+(?<!\.)@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;

            if(typeof value === 'object' && value) {
                for (const v of value) {
                    if(v.hasOwnProperty('value')){
                        if(v.value && !regex.test(v.value)) {
                            return this.$t('invalidEmailAddress');
                        }
                    } else {
                        if(v && !regex.test(v)) {
                            return this.$t('invalidEmailAddress');
                        }
                    } 
                }
            } else {
                if(value && !regex.test(value)) {
                    return this.$t('invalidEmailAddress');
                }
            }
            return true;
        },
        emailWithCommas(value) {
            if(value) {
                for (const email of value.split(',')) {
                    if(this.email(email) !== true)
                        return this.email(email);
                }
            }

            return true;
        },
        phoneWithCommas(value) {
            if(value) {
                var PhoneNumber = require('awesome-phonenumber');
                const cleanedValue = value.replace(/[().\-\s/]/g, '');

                for (const number of cleanedValue.split(',')) {
                    if(!(new PhoneNumber(number, 'FR')).isValid())
                        return this.$t('errorPatternPhoneNumber');
                }
            }

            return true;
        },
        messageSender(value) {
            if(value) {
                // On enlève les variable pour la validation
                let valueReplaced = value.replace(/\{\{.*?\}\}/g, '');
                let regex = "";
                
                if(value !== valueReplaced) { // Si le sender contient des variables alors on ne check pas la taille minimum
                    regex = /^(?![0-9]+$)[A-Za-z0-9\s-+\.]{0,11}$/i;
                } else { // Sinon on check avec la taille minimum
                    regex = /^(?![0-9]+$)[A-Za-z0-9\s-+\.]{3,11}$/i;
                }

                if(valueReplaced && !regex.test(valueReplaced)) {
                    return this.$t('invalidMsgSenderAddress');
                }
            }

            return true;
        },
        regex(value, regexDef, errMessage) {
            const regex = new RegExp(regexDef);
            
            if(value && !regex.test(value)) {
                return this.$t(errMessage);
            }

            return true;
        },
        regexForArrayValue(value, regexDef, errMessage) {
            const regex = new RegExp(regexDef);

            if(typeof value === 'object') {
                for (const v of value) {
                    if(v && !regex.test(v)) {
                        return this.$t(errMessage);
                    }
                }
            }            

            return true;
        },
        requiredIfAnotherEmpty(value, fieldName, errMessage) {
            if((!value || value == "" || value.length == 0) && (!this.model[fieldName] || this.model[fieldName] == "" || this.model[fieldName].length == 0)) {
                return this.$t(errMessage);
            }

            return true;
        },
        requiredIfOthersAreEmpty(value, fieldNames, errMessage) {
            if(!value || value == "" || value.length == 0) {
                for (const fieldName of fieldNames)
                    if(this.model[fieldName] && this.model[fieldName] !== "" && this.model[fieldName].length !== 0)
                        return true;
            } else {
                return true;
            }

            return this.$t(errMessage);
        },
        requiredIfAnotherEquals(value, objectField, errMessage) {
            if(!value || _.isEmpty(value)) {
                if(objectField.subobject) {
                    if(typeof this.model[objectField.object] !== 'undefined' && typeof this.model[objectField.object][objectField.subobject] !== 'undefined') {
                        if(this.model[objectField.object][objectField.subobject] === objectField.equal) {
                            return this.$t(errMessage);
                        }
                    }
                } else {
                    if(typeof this.model[objectField.object] !== 'undefined') {
                        if(this.model[objectField.object] === objectField.equal) {
                            return this.$t(errMessage);
                        }
                    }
                }
            }

            return true;
        },
        dateIsSuperior(value, fieldName, errMessage){
            if(value) {
                if(new Date(value).getTime() >= new Date(this.model[fieldName]).getTime()){
                    return true;
                }
                return this.$t(errMessage);
            } else {
                return true;
            }
        }
    }
}