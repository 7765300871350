<template>
    <div class="pa-6 pt-0 workflowAddContainer">
        <h2 v-if="data.conditions && data.conditions.length > 0">{{$t('conditionsLabel')}}</h2>
        <div @click="addElement(condition)" v-ripple v-for="condition, index in data.conditions" v-show="data.conditions && data.conditions.length > 0" :key="'condition_'+index" class="btnAddElement mb-1">
            <div class="icon  pa-3">
                <v-icon color="primary" >{{condition.icon}}</v-icon>
            </div>
            <p class="mb-0 pl-3">{{$t(condition.label)}}</p>
        </div>
        <h2 v-if="data.internal_notifications && data.internal_notifications.length > 0">{{$t('internalNotificationsLabel')}}</h2>
        <div @click="addElement(notif)" v-ripple v-for="notif, index in data.internal_notifications" v-show="data.internal_notifications && data.internal_notifications.length > 0" :key="'internal_notif_'+index" class="btnAddElement mb-1">
            <div class="icon  pa-3">
                <v-icon color="primary" >{{notif.icon}}</v-icon>
            </div>
            <p class="mb-0 pl-3">{{$t(notif.label)}}</p>
        </div>
        <h2 v-if="data.external_communication && data.external_communication.length > 0">{{$t('externalCommunicationLabel')}}</h2>
        <div @click="addElement(com)" v-ripple v-for="com, index in data.external_communication" v-show="data.external_communication && data.external_communication.length > 0" :key="'external_communication_'+index" class="btnAddElement mb-1">
            <div class="icon  pa-3">
                <v-icon color="primary" >{{com.icon}}</v-icon>
            </div>
            <p class="mb-0 pl-3">{{$t(com.label)}}</p>
        </div>
        <h2 v-if="data.actions && data.actions.length > 0">{{$t('actionsLabel')}}</h2>
        <div @click="addElement(action)" v-ripple v-for="action, index in data.actions" v-show="data.actions && data.actions.length > 0" :key="'action_'+index" class="btnAddElement mb-1">
            <div class="icon  pa-3">
                <v-icon color="primary" >{{action.icon}}</v-icon>
            </div>
            <p class="mb-0 pl-3">{{$t(action.label)}}</p>
        </div>
        <h2 v-if="data.delais && data.delais.length > 0">{{$t('delaisLabel')}}</h2>
        <div @click="addElement(delai)" v-ripple v-for="delai, index in data.delais" v-show="data.delais && data.delais.length > 0" :key="'delai_'+index" class="btnAddElement mb-1">
            <div class="icon  pa-3">
                <v-icon color="primary" >{{delai.icon}}</v-icon>
            </div>
            <p class="mb-0 pl-3">{{$t(delai.label)}}</p>
        </div>
        <h2 v-if="data.contact_properties && data.contact_properties.length > 0">{{$t('contactPropertiesLabel')}}</h2>
        <div @click="addElement(property)" v-ripple v-for="property, index in data.contact_properties" v-show="data.contact_properties && data.contact_properties.length > 0" :key="'contact_property_'+index" class="btnAddElement mb-1">
            <div class="icon  pa-3">
                <v-icon color="primary" >{{property.icon}}</v-icon>
            </div>
            <p class="mb-0 pl-3">{{$t(property.label)}}</p>
        </div>
    </div>
</template>
<script>
import GenericDataService from '@/services/GenericDataService'
export default {
    name:"WorkflowAddElement",
    data() {
        return {
            data: {},
        }
    },
    created() {
        if(!this.data.hasOwnProperty('contact_properties')){
            GenericDataService.getData('/workflow/getWorkflowItemList').then((response) => {
                this.data = response.data.data
                if(!this.$route.params.operation_id){
                    this.data.actions = this.data.actions.filter((e) => e.label != 'affect_contact')
                }
            })
        }
    },
    mounted() {
        if(!this.data.hasOwnProperty('contact_properties')){
            GenericDataService.getData('/workflow/getWorkflowItemList').then((response) => {
                this.data = response.data.data
                if(!this.$route.params.operation_id){
                    this.data.actions = this.data.actions.filter((e) => e.label != 'affect_contact')
                }
            })
        }
    },
    methods: {
        addElement(action) {
            this.$emit('addElement', action)
        }
    },
}
</script>
<style lang="scss">
    .workflowAddContainer {
        h2{
            margin-top:15px;
            font-size:15px;
            font-weight:700;
            text-transform: capitalize;
            color: var(--v-pink-base) !important;
        }
        .btnAddElement {
            cursor: pointer;
            display:flex;
            align-items: center;
            border: 1px solid rgba(0,0,0,0.1);
            padding:4px;
            border-radius:8px;
            margin:6px 0;
            font-weight:600;

            &:hover{
                color: var(--v-primary-base);
                background-color:#fafafa;
                border: 1px solid rgba(0,0,0,0.2);
            }
        }
        .icon {
            display:flex; 
            align-items:center; 
            justify-content:center;
        }
    }
</style>