<template>
    <v-container fluid class="pa-6">
        <DatatableV2 :displayTitle="false" :flat="false" :url="apiUrl" @copyApiToken="copyApiToken" />
    </v-container>
</template>

<script>
import DatatableV2 from '@/components/DatatableV2';

export default {
    name: 'ApiList',
    components: { DatatableV2 },
    data() {
        return {
            apiUrl: "/externalapi/list",
        }
    },
    methods: {
        copyApiToken(api) {
            navigator.clipboard.writeText(api.token).then(() => {
                this.$store.dispatch('ADD_SNACK', { message : this.$t('tokenCopiedSuccessfully') }).then((e) => {
                    this.$store.dispatch("REMOVE_SNACK")
                });
            });
        }
    }
}
</script>

<style lang="scss">
</style>