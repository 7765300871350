<template>
    <v-container fluid class="pa-6">
        <div class="d-flex justify-space-between align-center">
            <div class="d-flex">
                <v-select class="mt-0 pt-0" hide-details :items="entities" :label="$t('entities')" clearable single-line multiple v-model="filters['entities']"></v-select>

                <span class="d-flex align-center ml-3">
                    <v-btn fab text small @click="prev" :disabled="isMinMonth()">
                        <v-icon small>$prev</v-icon>
                    </v-btn>
                    <p class="mb-0 text-center" style="min-width: 120px;"><b>{{ currentMonth }}</b></p>
                    <v-btn fab text small @click="next">
                        <v-icon small>$next</v-icon>
                    </v-btn>
                </span>
            </div>
            <div class="d-flex">
                <v-btn color="primary" depressed @click="getExportUrl" class="ml-3">{{ $t('export_transactions') }}</v-btn>
            </div>
        </div>

        <div class="mt-6" v-if="isCurrentMonth() || isInPreviousMonths()">
            <v-card>
                <v-card-title>
                    <span><b>{{ $t('transactions_completed') }}</b></span>
                    <v-spacer></v-spacer>
                    <v-chip color="#D5D6E3" style="color: #2D2B67;" dark v-if="transactionsCompleted.length > 0">
                        <b>{{ totalAmountCompleted }} €</b>
                    </v-chip>
                </v-card-title>
                <v-card-text>
                    <v-simple-table v-if="transactionsCompleted.length > 0">
                        <template v-slot:default>
                            <thead>
                                <tr>
                                    <th>{{ $t('dates') }}</th>
                                    <th>{{ $t('entity') }}</th>
                                    <th>{{ $t('motif') }}</th>
                                    <th>{{ $t('detailLabel') }}</th>
                                    <th class="text-center">{{ $t('quantity') }}</th>
                                    <th class="text-center">{{ $t('amount') }}</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="transaction in transactionsCompleted" :key="transaction.id">
                                    <td>{{ parseFullDateToFr(transaction.date_start) }}</td>
                                    <td>{{ transaction.entity_id.label }}</td>
                                    <td>{{ $t(transaction.canal) }}</td>
                                    <td>{{ transaction.name }}</td>
                                    <td class="text-center">{{ transaction.quantity }}</td>
                                    <td class="text-center">{{ transaction.amount }} €</td>
                                </tr>
                            </tbody>
                        </template>
                    </v-simple-table>
                    <v-alert v-else border="left" color="info" text>
                        {{ $t('no_transaction_completed') }}
                    </v-alert>
                </v-card-text>
            </v-card>
        </div>

        <div class="mt-6" v-if="isCurrentMonth() || isInNextMonths()">
            <v-card>
                <v-card-title>
                    <span><b>{{ $t('transactions_to_come') }}</b></span>
                    <v-spacer></v-spacer>
                    <v-chip color="#D5D6E3" style="color: #2D2B67;" dark v-if="transactionsToCome.length > 0">
                        <b>{{ totalAmountToCome }} €</b>
                    </v-chip>
                </v-card-title>
                <v-card-text>
                    <v-simple-table v-if="transactionsToCome.length > 0">
                        <template v-slot:default>
                            <thead>
                                <tr>
                                    <th>{{ $t('dates') }}</th>
                                    <th>{{ $t('entity') }}</th>
                                    <th>{{ $t('motif') }}</th>
                                    <th>{{ $t('detailLabel') }}</th>
                                    <th class="text-center">{{ $t('quantity') }}</th>
                                    <th class="text-center">{{ $t('amount') }}</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="transaction in transactionsToCome" :key="transaction.id">
                                    <td>{{ parseFullDateToFr(transaction.date_start) }}</td>
                                    <td>{{ transaction.entity_id.label }}</td>
                                    <td>{{ $t(transaction.canal) }}</td>
                                    <td>{{ transaction.name }}</td>
                                    <td class="text-center">{{ transaction.quantity }}</td>
                                    <td class="text-center">{{ transaction.amount }} €</td>
                                </tr>
                            </tbody>
                        </template>
                    </v-simple-table>
                    <v-alert v-else border="left" color="info" text>
                        {{ $t('no_transaction_to_come') }}
                    </v-alert>
                </v-card-text>
            </v-card>
        </div>

        <v-dialog persistent width="400" v-model="showDownload">
            <v-card>
                <v-card-text class="pa-6">
                    <h5 class="icon-title">
                    {{$t('downloadReady')}}
                    <v-btn @click="showDownload = false" text class="square">
                        <v-icon small>$close</v-icon>
                    </v-btn>
                    </h5>
                    <div class="text-center mt-4 mb-2">
                        <a :href="downloadUrl" @click="showDownload = false" download class="exportBtn">{{$t('clickForDownload')}}</a>
                    </div>
                </v-card-text>
            </v-card>
        </v-dialog>

        <Loader v-if="openLoader"/>
    </v-container>
</template>

<script>
import GenericDataService from '@/services/GenericDataService';
import dateManipulation from '@/mixins/dateManipulation';
import Loader from '@/components/Loader.vue';

export default {
    name: "SuiviConso",
    mixins: [dateManipulation],
    components: { Loader },
    data() {
        return {
            api: process.env.VUE_APP_API_URL,
            openLoader: false,
            downloadUrl: '',
            showDownload:false,
            currentMonth: '',
            entities: [],
            transactions: [],
            filters: {
                entities: [],
                date: null
            }
        }
    },
    created() {
        this.loadEntities();

        let date = new Date();
        date.setDate(1);
        this.filters.date = date;
    },
    methods: {
        next() {
            this.filters.date = this.changeMonth(this.filters.date, 1);
        },
        prev() {
            if(!this.isMinMonth())
                this.filters.date = this.changeMonth(this.filters.date, -1);
        },
        changeMonth(date, months) {
            let newDate = new Date(date);
            newDate.setMonth(newDate.getMonth() + months);
            return newDate;
        },
        isMinMonth() {
            const min_month = new Date('2024-01-01');
            return this.filters.date.getMonth() === min_month.getMonth() && this.filters.date.getFullYear() === min_month.getFullYear();
        },
        isCurrentMonth() {
            const now = new Date();
            return this.filters.date.getMonth() === now.getMonth() && this.filters.date.getFullYear() === now.getFullYear();
        },
        isInPreviousMonths() {
            const now = new Date();
            return this.filters.date < new Date(now.getFullYear(), now.getMonth(), 1);
        },
        isInNextMonths() {
            const now = new Date();
            return this.filters.date >= new Date(now.getFullYear(), now.getMonth() + 1, 1);
        },
        loadTransactions() {
            GenericDataService.getData("/facturation/getTransactions?filters=" + JSON.stringify(this.filters)).then((response) => {
                this.transactions = response.data.data;
            });
        },
        loadEntities() {
            GenericDataService.getData("/facturation/getAvailableEntities").then((response) => {
                this.entities = response.data.data;
            });
        },
        getExportUrl() {
            this.openLoader = true;

            let exportCompleted = this.isCurrentMonth() || this.isInPreviousMonths() ? 1 : 0;
            let exportToCome = this.isCurrentMonth() || this.isInNextMonths() ? 1 : 0;

            GenericDataService.postData("/facturation/exportTransactions?exportCompleted=" + exportCompleted + "&exportToCome=" + exportToCome + "&filters=" + JSON.stringify(this.filters)).then((response) => {
                this.downloadUrl = this.api + response.data.data.directDownload;
                this.showDownload = true;
                this.openLoader = false;
            }).catch((e) => {
                this.openLoader = false;
            });
        }
    },
    computed: {
        transactionsCompleted() {
            return this.transactions.filter(transaction => transaction.status === 'COMPLETED');
        },
        transactionsToCome() {
            return this.transactions.filter(transaction => transaction.status !== 'COMPLETED');
        },
        totalAmountCompleted() {
            let amount = this.transactionsCompleted.reduce((acc, transaction) => acc + transaction.amount, 0);
            return amount % 1 === 0 ? amount : amount.toFixed(2);
        },
        totalAmountToCome() {
            let amount = this.transactionsToCome.reduce((acc, transaction) => acc + transaction.amount, 0);
            return amount % 1 === 0 ? amount : amount.toFixed(2);
        }
    },
    watch: {
        filters: {
            handler(val, old) {
                this.currentMonth = this.$func.capitalizeFirstLetter(this.timestampToDate((val.date.getTime() / 1000), 'monthYear'));
                this.loadTransactions();
            },
            deep: true
        }
    },
}
</script>