<template>
    <v-container fluid class="pa-6">
        <DatatableV2 ref="webhookListDatatable" :displayTitle="false" :flat="false" :url="apiUrl" @openWebhookForm="openWebhookForm" />
    </v-container>
</template>

<script>;
import DatatableV2 from '@/components/DatatableV2';

export default {
    name: 'WebhookList',
    components: { DatatableV2 },
    data() {
        return {
            apiUrl: "/webhook/list",
        }
    },
    methods: {
        openWebhookForm(id, webhook) {
            this.$refs.webhookListDatatable.getForm("/webhook/getForm?trigger_id=" + webhook.trigger_id);
        }
    }
}
</script>

<style lang="scss">
</style>