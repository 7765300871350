import store from '../../store'
import GenericDataService from '@/services/GenericDataService'

export default [
    // {
    //     path: '/modeles-operations',
    //     meta: { breadCrumb: 'typeOperations'},
    //     redirect:{name: 'OperationsTypesList'},
    //     component: function () {
    //         return import(/* webpackChunkName: "operations" */ '../../modules/operationstype/views/OperationsType.vue')
    //     },
    //     children: [
    //         {
    //             path:'',
    //             name:'OperationsTypesList',
    //             meta: { title:'OperationsTypesList', titleDocument: "OperationsTypesList"},
    //             component: function () {
    //                 return import(/* webpackChunkName: "operationList" */ /* webpackPrefetch: true */'../../modules/operationstype/views/OperationsTypesList.vue')
    //             },
    //         },
    //         {
    //             path:':operationtype_id',
    //             meta: { breadCrumb: "operationTypeDetail", titleDocument: "operationTypeDetail", disabled: true } ,
    //             name: 'operationTypeDetail',
    //             beforeEnter: (to, from, next) => {
    //                 GenericDataService.getData('/menu/getsubmenu?module=operationtype&id=' + to.params.operationtype_id).then((response) => {
    //                     let submenu = response.data.data;
    //                     store.dispatch('base/SET_SUB_MENU', submenu)
    //                     next();
    //                 })
    //             },
    //             redirect:{name: 'OperationsTypeDetailConfiguration'},
    //             component: function () {
    //                 return import(/* webpackChunkName: "operationsDetail" */ '../../modules/operationstype/views/OperationTypeDetail.vue')
    //             },
    //             children: [
    //                 {
    //                     path:'configuration',
    //                     name:'OperationsTypeDetailConfiguration',
    //                     meta: { breadCrumb: "OperationsTypeDetailConfiguration", titleDocument: "operationsTypeDetailConfiguration", title:'OperationsTypeDetailConfiguration', rights: ["operation/get"] } ,
    //                     beforeEnter: (to, from, next) => {
    //                         next();
    //                     },
    //                     component: function () {
    //                         return import(/* webpackChunkName: "operationsDetailDashboard" */ '../../modules/operationstype/views/OperationsTypeDetailConfiguration.vue')
    //                     },
    //                 },
    //                 {
    //                     path:'retroplanning',
    //                     name:'OperationsTypeDetailRetroplanning',
    //                     meta: { breadCrumb: "OperationsTypeDetailRetroplanning", titleDocument: "operationsTypeDetailRetroplanning", title:'OperationsTypeDetailRetroplanning', rights: ["operation/get"] } ,
    //                     beforeEnter: (to, from, next) => {
    //                         next();
    //                     },
    //                     component: function () {
    //                         return import(/* webpackChunkName: "operationsDetailDashboard" */ '../../modules/operationstype/views/OperationsTypeDetailRetroplanning.vue')
    //                     },
    //                 },
    //                 {
    //                     path:'workflows',
    //                     name:'OperationsTypeDetailWorkflows',
    //                     redirect: {name:'WorkflowOperationTypeList'},
    //                     meta: { breadCrumb: "OperationsTypeDetailWorkflows", titleDocument: "operationsTypeDetailWorkflows", title:'OperationsTypeDetailWorkflows', rights: ["operation/get"] } ,
    //                     component: function () {
    //                         return import(/* webpackChunkName: "operationsDetailDashboard" */ '../../modules/workflow/views/Workflow.vue')
    //                     },
    //                     children: [
    //                         {
    //                             path:'',
    //                             name:'WorkflowOperationTypeList',
    //                             meta: { title:'WorkflowOperationTypeList', titleDocument: "operationsTypeDetailWorkflows"},
    //                             component: function () {
    //                                 return import(/* webpackChunkName: "contactsList" */ /* webpackPrefetch: true */ '../../modules/operationstype/views/OperationsTypeDetailWorkflows.vue')
    //                             },
    //                         },
    //                         {
    //                             path:':workflow_id',
    //                             meta: { breadCrumb: "WorkflowOperationTypeDetail", titleDocument: "OperationsTypeDetailWorkflows", title: "workflowDetails", subMenu: false } ,
    //                             name: 'WorkflowOperationTypeDetail',
    //                             component: function () {
    //                                 return import(/* webpackChunkName: "contactsDetail" */ /* webpackPrefetch: true */ '../../modules/workflow/views/WorkflowDetail.vue')
    //                             }
    //                         },
    //                     ]
    //                 },
    //                 {
    //                     path:'medias',
    //                     name:'OperationsTypeDetailMedias',
    //                     meta: { breadCrumb: "OperationsTypeDetailMedias", titleDocument: "operationsTypeDetailMedias", title:'OperationsTypeDetailMedias', rights: ["operation/get"] } ,
    //                     beforeEnter: (to, from, next) => {
    //                         next();
    //                     },
    //                     component: function () {
    //                         return import(/* webpackChunkName: "operationsDetailDashboard" */ '../../modules/operationstype/views/OperationsTypeDetailMedias.vue')
    //                     },
    //                 },
    //             ]
    //         }
    //     ]
    // },
    {
        path: '/operations',
        meta: { breadCrumb: 'operations', rights: ["operation/menu", "operation/add", "operation/update", "operation/delete"] },
        redirect:{name: 'OperationsList'},
        component: function () {
            return import(/* webpackChunkName: "operations" */ '../../modules/operations/views/Operation.vue')
        },
        beforeEnter: (to, from, next) => {
            if(store.state.base && store.state.base.inSubMenu){
                GenericDataService.getData('/menu/getsubmenu?module=retroplanning').then((response) => {
                    let submenu = response.data.data;
                    store.dispatch('base/SET_SUB_MENU', submenu)
                    next();
                })
            } else {
                GenericDataService.getData('/menu/checkIfInSub?name=OperationsList').then((response) => {
                    if(response.data.data == true){
                        store.dispatch('base/SET_IN_SUB', true)
                        GenericDataService.getData('/menu/getsubmenu?module=retroplanning').then((response) => {
                            let submenu = response.data.data;
                            store.dispatch('base/SET_SUB_MENU', submenu)
                            next();
                        })
                    } else {
                        next();
                    }
                })
            }
                         
        },
        children: [
            {
                path:'',
                name:'OperationsList',
                meta: { title:'OperationsList', titleDocument: "operationsList", rights: ["operation/menu", "operation/add", "operation/update", "operation/delete"] },
                component: function () {
                    return import(/* webpackChunkName: "operationList" */ /* webpackPrefetch: true */'../../modules/operations/views/OperationList.vue')
                },
                beforeEnter: (to, from, next) => {
                    if(store.state.base && store.state.base.inSubMenu){
                        GenericDataService.getData('/menu/getsubmenu?module=retroplanning').then((response) => {
                            let submenu = response.data.data;
                            store.dispatch('base/SET_SUB_MENU', submenu)
                            next();
                        })
                    } else {
                        GenericDataService.getData('/menu/checkIfInSub?name=OperationsList').then((response) => {
                            if(response.data.data == true){
                                store.dispatch('base/SET_IN_SUB', true)
                                GenericDataService.getData('/menu/getsubmenu?module=retroplanning').then((response) => {
                                    let submenu = response.data.data;
                                    store.dispatch('base/SET_SUB_MENU', submenu)
                                    next();
                                })
                            } else {
                                next();
                            }
                        })
                    }
                                 
                },
            },
            {
                path:':operation_id',
                meta: { breadCrumb: "operationDetail", titleDocument: "operationDetail" } ,
                name: 'OperationDetail',
                beforeEnter: (to, from, next) => {
                    if(to.name != "OperationsConfiguration" && to.name != "OperationsDetailReport"){
                        GenericDataService.getData('/operation/' + to.params.operation_id + '/get').then((response) => {
                            if(response.data.data.status == "DRAFT"){
                                next({name:'OperationsConfiguration', params:{operation_id: to.params.operation_id}});
                                return
                            } else {
                                //setTimeout(() => {
                                    GenericDataService.getData('/menu/getsubmenu?module=operation&id='+to.params.operation_id).then((response) => {
                                        let submenu = response.data.data;
                                        store.dispatch('base/SET_SUB_MENU', submenu)
                                        next();
                                        return
                                    })  
                                    
                                //}, 100);
                               
                            }
                        });
                    } else {
                        next();
                        return
                    }                   
                },
                redirect:{name: 'OperationsDetailDashboard'},
                component: function () {
                  return import(/* webpackChunkName: "operationsDetail" */ '../../modules/operations/views/OperationDetail.vue')
                },
                children:[
                  {
                    path:'dashboard',
                    name:'OperationsDetailDashboard',
                    meta: { breadCrumb: "operationDetailDashboard", titleDocument: "operationDetailDashboard", title:'operationsDetailDashboard', rights: ["operation/get"] } ,
                    component: function () {
                        return import(/* webpackChunkName: "operationsDetailDashboard" */ '../../modules/operations/views/OperationDetailDashboard.vue')
                    },
                    beforeEnter: (to, from, next) => {
                        GenericDataService.getData('/menu/getsubmenu?module=operation&id='+to.params.operation_id).then((response) => {
                            let submenu = response.data.data;
                            store.dispatch('base/SET_SUB_MENU', submenu)
                            next();
                        })  
                    }
                  },
                  {
                        path:'config',
                        name:'OperationsConfiguration',
                        meta: { breadCrumb: "OperationsConfiguration", titleDocument: "operationDetailConfiguration", title:'OperationsConfiguration', rights: ["operation/get"] } ,
                        component: function () {
                            return import(/* webpackChunkName: "operationsDetailDashboard" */ '../../modules/operations/views/OperationConfiguration.vue')
                        },
                  },
                  {
                        path:'campaign',
                        name: 'OperationCampaigns',
                        meta: { title: 'OperationCampaigns', titleDocument: "operationDetailCampaigns", rights: ["retroplanning/add", "retroplanning/update"] },
                        component: function() {
                            return import(/* webpackChunkName: "campaignOutPList" */ '../../modules/campagnes/views/Campaign.vue');
                        },
                        children: [
                            {
                                path: ':id',
                                name: 'OperationCampaignDetail',
                                meta: {  title: 'OperationCampaignDetail', titleDocument: "operationDetailCampaignDetail", rights: ["retroplanning/get"]  },
                                redirect: { name: 'OperationCampaignDetailDashboard' },
                                component: function() {
                                    return import(/* webpackChunkName: "OperationCampaignDetail" */ '../../modules/campagnes/views/Campaign/CampaignDetail.vue');
                                },
                                beforeEnter: (to, from, next) => {
                                    GenericDataService.getData('/menu/getsubmenu?module=operation&id='+to.params.operation_id).then((response) => {
                                        let submenu = response.data.data;
                                        store.dispatch('base/SET_SUB_MENU', submenu)
                                        next();
                                    })  
                                },
                                children: [
                                    {
                                        path:'dashboard',
                                        meta: { breadCrumb: "OperationCampaignDetailDashboard", titleDocument: "OperationCampaignDetailDashboard", title: "OperationCampaignDetailDashboard", rights: ["retroplanning/get"] } ,
                                        name: 'OperationCampaignDetailDashboard',
                                        component: function () {
                                            return import(/* webpackChunkName: "OperationCampaignDetailDashboard" */ '../../modules/campagnes/views/Campaign/CampaignDashboard.vue')
                                        }
                                    }
                                    
                                ]
                            },
                        ]
                  },
                    {
                        path:'opportunity',
                        meta: { breadCrumb: "opportunity", titleDocument: "OperationsDetailOpportunity", title: "OperationsDetailOpportunity", rights: ["operation/get"] } ,
                        name: 'OperationsDetailOpportunity',
                        component: function () {
                            return import(/* webpackChunkName: "operationsDetailLead" */ '../../modules/operations/views/OperationsDetailOpportunity.vue')
                        }
                    },
                    {
                        path:'leads',
                        meta: { breadCrumb: "leads", titleDocument: "OperationsDetailLead", title: "OperationsDetailLead", rights: ["operation/get"] } ,
                        name: 'OperationsDetailLead',
                        component: function () {
                            return import(/* webpackChunkName: "operationsDetailLead" */ '../../modules/operations/views/OperationDetailLeadList.vue')
                        },
                        beforeEnter: (to, from, next) => {
                            GenericDataService.getData('/menu/getsubmenu?module=operation&id='+to.params.operation_id).then((response) => {
                                let submenu = response.data.data;
                                store.dispatch('base/SET_SUB_MENU', submenu)
                                next();
                            })  
                        }
                    },
                    {
                        path:'planning',
                        meta: { breadCrumb: "planning", titleDocument: "OperationsDetailPlanning", title: "OperationsDetailPlanning", rights: ["retroplanning/get"] } ,
                        name: 'OperationsDetailPlanning',
                        component: function () {
                            return import(/* webpackChunkName: "operationsDetailLead" */ '../../modules/operations/views/OperationsDetailPlanning.vue')
                        }
                    },
                    {
                        path:'passage',
                        meta: { breadCrumb: "passage", titleDocument: "OperationsDetailPassage", title: "OperationsDetailPassage", rights: ["retroplanning/get"] } ,
                        name: 'OperationsDetailPassage',
                        component: function () {
                            return import(/* webpackChunkName: "operationsDetailLead" */ '../../modules/operations/views/OperationsDetailPassage.vue')
                        }
                    },
                    {
                        path:'report',
                        meta: { breadCrumb: "report", titleDocument: "OperationsDetailReport", title: "OperationsDetailReport", rights: ["operation/get"] } ,
                        name: 'OperationsDetailReport',
                        component: function () {
                            return import(/* webpackChunkName: "operationsDetailReport" */ '../../modules/operations/views/OperationsDetailReport.vue')
                        }
                    },
                    {
                        path:'inscriptions',
                        meta: { breadCrumb: "inscriptions", titleDocument: "operationDetailRegistration", title: "OperationsDetailReport", rights: ["operation/get"] } ,
                        name: 'operationDetailRegistration',
                        component: function () {
                            return import(/* webpackChunkName: "operationsDetailReport" */ '../../modules/operations/views/OperationsDetailRegistration.vue')
                        }
                    }
                ]
              }
        ]
    }
]