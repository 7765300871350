<template>
    <div>
        <v-container fluid class="pa-6">
            <v-row justify="center" v-if="this.$store.state.auth.user.is_coach && canUpdateReport()">
                <v-col cols="10" class="text-center">
                    <v-alert color="info" text class="mb-0">{{ $t('pleasecheckreportbeforesubmit') }}</v-alert>
                </v-col>
            </v-row>

            <v-row justify="center">
                <v-col cols="6">
                    <v-card>
                        <v-card-title>
                            {{ $t('customercomment') }}
                        </v-card-title>
                        <v-card-text>
                            <span v-if="data.operation && data.operation.report_comments.customercomment">
                                {{ data.operation.report_comments.customercomment }}
                            </span>
                            <v-alert v-else border='left' :icon="'$warning_s'" type="info" text class="mb-0">{{ $t('nocustomercomment') }}</v-alert>
                        </v-card-text>

                        <v-card-title>
                            {{ $t('clientcomment') }}
                        </v-card-title>
                        <v-card-text>
                            <span v-if="data.operation && data.operation.report_comments.clientcomment">
                                {{ data.operation.report_comments.clientcomment }}
                            </span>
                            <v-alert v-else border='left' :icon="'$warning_s'" type="info" text class="mb-0">{{ $t('noclientcomment') }}</v-alert>
                        </v-card-text>

                        <v-card-actions class="pa-4 pt-0" v-if="canUpdateReport()">
                            <v-btn color="primary" @click="getForm('operation/' + data.operation.id + '/getForm?formName=reportComments')">
                                {{ $t("modify") }}
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-col>

                <v-col cols="4">
                    <v-card>
                        <v-card-title class="d-flex align-center mb-6">
                            {{ $t('photoandvideolibrary') }}
                            <v-spacer></v-spacer>
                            <v-btn color="primary" v-if="canUpdateReport()" @click="getForm('operation/' + data.operation.id + '/getReportMediasForm')">
                                {{ $t("modify") }}
                            </v-btn>
                        </v-card-title>

                        <v-card-text>
                            <div v-if="data.medias && data.medias.length > 0">
                                <v-carousel v-model="model" hide-delimiters  height="400" :show-arrows="data.medias.length == 1 ? false : true">
                                    <template v-slot:prev="{ on, attrs }">
                                        <v-btn v-bind="attrs" v-on="on" text color="lightgrey" fab>
                                            <v-icon text color="darkgrey">$prev</v-icon>
                                        </v-btn>
                                    </template>
                                    <template v-slot:next="{ on, attrs }">
                                        <v-btn v-bind="attrs" v-on="on" text color="grey" fab>
                                            <v-icon text color="darkgrey">$next</v-icon>
                                        </v-btn>
                                    </template>
                                    <v-carousel-item v-for="(media, i) in data.medias" :key="'media_'+i">
                                        <div style="height:100%;">
                                            <v-img :src="media.url" v-if="media.object.mime.indexOf('image') !== -1" height="100%" contain style="z-index:10"></v-img>
                                            <video controls height="100%" v-else>
                                                <source :src="media.url" :type="media.object.mime" />
                                            </video>
                                            <div class="black">
                                                <span>{{ media.object.title }}</span>
                                            </div>
                                        </div>
                                    </v-carousel-item>
                                </v-carousel>
                            </div>
                            <v-alert v-else border='left' :icon="'$warning_s'" type="info" text class="mb-0">
                                {{ $t('noMediaAvailable') }}
                            </v-alert>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>

            <v-row justify="center">
                <v-col cols="10">
                    <v-card>
                        <v-card-title>
                            {{ $t('backonsales') }}
                        </v-card-title>

                        <v-card-text>
                            <v-simple-table>
                                <template v-slot:default>
                                    <thead>
                                        <tr>
                                            <th>{{ $t("contact") }}</th>
                                            <th>{{ $t("vendor") }}</th>
                                            <th>{{ $t("rdv") }}</th>
                                            <th class="text-center">{{ $t("confirmed") }}</th>
                                            <th class="text-center">{{ $t("cameRdv") }}</th>
                                            <th class="text-center">{{ $t("purchased") }}</th>
                                            <th class="text-center">{{ $t("financing") }}</th>
                                            <th>{{ $t("sell") }}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="sale in data.sales" @click="openDemand(sale)" :style="canUpdateReport() ? 'cursor: pointer;' : ''">
                                            <td><!-- Contact -->
                                                {{ sale.contact_id.firstname }} {{ sale.contact_id.name }} {{ sale.contact_id.company }}
                                            </td>
                                            <td><!-- Vendeur -->
                                                {{ sale.affected_user.firstname }} {{ sale.affected_user.name }}
                                            </td>
                                            <td><!-- Date du rdv -->
                                                <span v-if="!sale.rdv">-</span>
                                                <span v-else-if="sale.rdv.type !== 'RDV'">{{ $t(sale.rdv.type) }}</span>
                                                <span v-else-if="sale.rdv.date_start">{{ parseFullDateToFr(sale.rdv.date_start) }}</span>
                                            </td>
                                            <td class="text-center"><!-- Confirmé -->
                                                <span v-if="!sale.rdv">-</span>
                                                <v-icon v-else-if="sale.rdv.status !== 'DRAFT' || sale.rdv.type !== 'RDV'" color="success">$check_s</v-icon>
                                                <v-icon v-else color="error">$delete</v-icon>
                                            </td>
                                            <td class="text-center"><!-- Venu -->
                                                <span v-if="!sale.rdv">-</span>
                                                <v-icon v-else-if="sale.rdv.status === 'FINISHED' && sale.rdv.is_success == 1" color="success">$check_s</v-icon>
                                                <v-icon v-else-if="sale.rdv.status === 'FINISHED' && sale.rdv.is_success == 0" color="error">$delete</v-icon>
                                                <v-icon v-else color="warning">$question_s</v-icon>
                                            </td>
                                            <td class="text-center"><!-- À acheté -->
                                                <v-icon v-if="sale.status === 'SUCCESS'" color="success">$check_s</v-icon>
                                                <v-icon v-else-if="sale.status === 'FAIL'" color="error">$delete</v-icon>
                                                <v-icon v-else color="warning">$question_s</v-icon>
                                            </td>
                                            <td class="text-center"><!-- Financement -->
                                                <v-icon v-if="sale.status === 'SUCCESS' && sale.details.desiredFunding === 'Oui'" color="success">$check_s</v-icon>
                                                <v-icon v-else-if="sale.status === 'FAIL' || sale.details.desiredFunding === 'Non'" color="error">$delete</v-icon>
                                                <v-icon v-else color="warning">$question_s</v-icon>
                                            </td>
                                            <td><!-- Achat (univers + nom de la demande) -->
                                                <span v-if="sale.status !== 'SUCCESS'">-</span>
                                                <span v-else>
                                                    <span v-if="sale.univers_id && sale.univers_id.label">{{ sale.univers_id.label }} </span>
                                                    <span v-else class="red--text">{{ $t("univers_missing") }} </span>
                                                    : {{ sale.name }}
                                                </span>
                                            </td>
                                        </tr>
                                    </tbody>
                                </template>
                            </v-simple-table>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
        </v-container>

        <FormDialogComponent v-if="form" ref="componentForm" :form="form" :action="'SET_DATA'" />
        <dialogSendReportComponent v-if="data.operation" :openSendReportDialog="openSendReportDialog" :report="data" @refresh="customRefresh(false, true)" @close="openSendReportDialog = false" />
    </div>
</template>

<script>
const subActionsCoach = {
    buttonSend: {
        "type": "button",
        "title": "sendmyreport",
        "color": "pink",
        "action": "emit",
        "emitAction": "sendReport",
    },
};

const subActions = {
    buttonSend: {
        "type": "button",
        "title": "confirmreport",
        "color": "pink",
        "action": "emit",
        "emitAction": "sendReport",
    },
};

import GenericDataService from '@/services/GenericDataService';
import FormDialogComponent from '@/components/dialogs/FormDialogComponent';
import dialogSendReportComponent from '@/components/dialogs/dialogSendReportComponent.vue';
import getForm from "@/mixins/mixins";
import dateManipulation from '@/mixins/dateManipulation';

export default {
    name: "OperationsDetailReport",
    components: { FormDialogComponent, dialogSendReportComponent },
    mixins: [getForm, dateManipulation],
    data() {
        return {
            data: {},
            model: 0,
            form: null,
        }
    },
    created() {
        this.getReport();
    },
    computed: {
        openSendReportDialog: {
            get: function(){
                return this.$store.state.operation.sendReportDialog;
            },
            set: function(val) {
                this.$store.dispatch("operation/SET_SEND_REPORT_DIALOG", false);
            }
        },
    },
    methods: {
        refreshOperation() {
            GenericDataService.getData('/operation/' + this.$route.params.operation_id + '/get').then((response) => {
                this.$store.dispatch('operation/SET_OPE_DATA', response.data.data);
            });
        },
        getReport() {
            GenericDataService.getData("/operation/" + this.$route.params.operation_id + "/getReport").then((response) => {
                this.data = response.data.data;

                if(this.$store.state.auth.user.is_coach && this.data.operation.report_status === "DRAFT") {
                    let actions = JSON.parse(JSON.stringify(subActionsCoach).replace("{{id}}", this.$route.params.operation_id));
                    this.$store.dispatch('base/SET_HEADER_ACTIONS', actions);
                } else if(!this.$store.state.auth.user.is_coach && this.data.operation.report_status === "TO_CONFIRM") {
                    this.$store.dispatch('base/SET_HEADER_ACTIONS', subActions);
                } else {
                    this.$store.dispatch('base/SET_HEADER_ACTIONS', {});
                }
            });
        },
        canUpdateReport() {
            if(this.data.operation && this.$store.state.auth.user.is_coach && this.data.operation.report_status === "DRAFT")
                return true;
            if(this.data.operation && !this.$store.state.auth.user.is_coach && this.data.operation.report_status === "TO_CONFIRM")
                return true;

            return false;
        },
        customRefresh(data, refreshOperation = false) {
            if(refreshOperation)
                this.refreshOperation();
            this.getReport();
        },
        openDemand(demand) {
            if(this.canUpdateReport()) {
                GenericDataService.getData('superlead/getByDemandId?id=' + demand.id + ('&operation_id=' + (demand.operation_id.id ? demand.operation_id.id : demand.operation_id))).then((response)=> {
                    this.$store.dispatch('lead/SET_SUPERLEAD_AND_OPEN_DIALOG', response.data.data)
                });
            }
        }
    }
}
</script>

<style lang="scss" scoped>
</style>
