import store from './store'

export const func = {
  /**
   * Permet de checker si l'utilisateur à le droit demandé
   * @param codes String ou Array de droit(s) => ex : 'contact/get' ou ['contact/get']
   * @param single True par défaut. Si un seul droit est nécessaire. Si false, alors il faut valider TOUS les droits demandés 
  */
  hasRight: (codes, single = true) => {
    let hasRight = false;

    let entity_id = store.state.auth.user.entity_id;

    if (!entity_id)
      return false;

    if (typeof codes === 'string') {
      store.state.auth.currentUser.rights.forEach(function (right) {
        if (entity_id.some(item => Object.values(right['entities']).includes(item))) {
          if (right['permits'].includes(codes)) {
            hasRight = true;
          }
        }
      });
    } else if (typeof codes === 'object') {
      let count = 0;

      for (let code of codes) {
        store.state.auth.currentUser.rights.forEach(function (right) {
          if (entity_id.some(item => Object.values(right['entities']).includes(item))) {
            if (right['permits'].includes(code)) {
              if (single) {
                hasRight = true;
              } else {
                count++;
              }
            }
          }
        });
      }

      if (single && count === codes.length) {
        hasRight = true;
      }
    }

    return hasRight;
  },
  getChipColorByStatus: (status) => {
    switch (status) {
      case "DRAFT": return "warning";
      case "PROVIDER_EXPECTATION": return "purple";
      case "PROGRAMMED": return "info";
      case "PROVIDER_REFUSAL": return "error";
      case "CANCELED": return "grey";
      case "COMPLETED": return "success";
      case "ON_GOING": return "info";
      case "ON_PAUSE": return "info";
      case "IN_PROGRESS": return "info"; // Operation
      case "CLOSED": return "primary"; // Operation
      case "CONFIRMED": return "info"; // Operation
      case "CANCELED": return "error"; // Operation
      case "IN_ERROR": return "error";
      case "FINISHED": return "info"; // Operation
      case "draft": return "grey"; // Factures
      case "open": return "info"; // Factures
      case "paid": return "success"; // Factures
      case "late": return "error"; // Factures
    }

    return "info";
  },
  /**
   * ASCII to unicode (decode Base64 to original data)
   * @param {string} b64 
   * @return {string}
   */
  atou: (b64) => {
    return decodeURIComponent(escape(atob(b64)));
  },
  /**
   * Unicode to ASCII (encode data to Base64)
   * @param {string} data 
   * @return {string}
   */
  utoa: (data) => {
    return btoa(unescape(encodeURIComponent(data)));
  },
  /**
   * Permet de capitaliser la première lettre d'une chaine de caractère
   * @param {string} string 
   * @return {string}
   */
  capitalizeFirstLetter: (string) => {
    return string[0].toUpperCase() + string.slice(1);
  }
}