
import axiosHttp from '@/http-common'
import router from '../router'
import store from '../store'
import { isNavigationFailure, NavigationFailureType } from '../router'

class GenericDataService {
  getObject(ref) {
    return axiosHttp.http.get("/test/get/?name="+ref);
  }
  getList(number) {
    return axiosHttp.http.get("/test/get/?name=liste"+number)
  }
  postData(url, payload, config = false) {
    return new Promise((resolve, reject) => {
      axiosHttp.http.post(url, JSON.stringify(payload), config ).then((response) => {
        
        let clone = JSON.parse(JSON.stringify(response))
        resolve(response)
      })
      .catch((error) => {
        console.log("error", error)
        if(error && !error.response){
          store.dispatch('ADD_ERROR', {errorMessage : "internetConnexionProblem", type: "user"});
        }
        if(error && error.response){
          let payload = error.response.data
          store.dispatch('ADD_ERROR', payload)
        }

        reject(error)
      })
    })
  }
  getData(url, config = false) {
    return new Promise((resolve, reject) => {
      axiosHttp.http.get(url, config).then((response) => {
        resolve(response)
      })
      .catch((error) => {
        console.log("error", error, error.response)
        
        if(error && !error.response){
          store.dispatch('ADD_ERROR', {errorMessage : "internetConnexionProblem", type: "user"});
        }
        if(error && error.response && error.response.status && error.response.status == 427){
          router.push({ name: 'Error427' }).catch(() => { });
        }
        if(error && error.response && (!error.response.status || error.response.status != 427)){
          let payload = error.response.data
          store.dispatch('ADD_ERROR', payload)
        }

        reject(error)
      })
    }) 
  }
  getDataThrowError(url, config = false) {
    return new Promise((resolve, reject) => {
      axiosHttp.http.get(url, config).then((response) => {
        resolve(response)
      })
      .catch((error) => {
         throw new Error(error)
      })
    }) 
  }
  setData(url, data) {
    return new Promise((resolve, reject) => {
      axiosHttp.http.post(url, data).then((response) => {
        resolve(response)
      })
      .catch((error) => {
        if(error && !error.response){
          store.dispatch('ADD_ERROR', {errorMessage : "internetConnexionProblem", type: "user"});
        }
        if(error.response && error.response.data){
          let payload = error.response.data
          store.dispatch('ADD_ERROR', payload)
        }
        
        // if(router.currentRoute.fullPath != '/nico-test'){
        //   router.push({name: 'Error404'}).catch(() => {});
        // }
        reject(error)
      })
    }) 
  }
}

export default new GenericDataService();
