<template>
    <div class="v-custom-contact-event col col-12 pa-0 input-form">
        <span class="label">
            <b>{{ $t("when_message_is") }}</b>
        </span>

        <v-row class="pa-4">
            <v-col cols="4" v-for="(event, eventName, index) in field.props.items" :key="eventName" class="pa-0">
                <v-checkbox v-model="model[fieldName]" :value="eventName" dense :hide-details="index !== Object.keys(field.props.items).length - 1">
                    <template v-slot:label>
                        <div>
                            <v-chip :color="event.color" dark>{{ $t("message_is_" + eventName) }}</v-chip>
                            <infoTooltip v-if="event.tooltip" :tooltip="event.tooltip" />
                        </div>
                    </template>
                </v-checkbox>
            </v-col>
        </v-row>
    </div>
</template>

<script>
import validate from '../../../functions/validations'
import infoTooltip from '@/components/infoTooltip'

export default {
    props: ["model", "fieldName", "field"],
    components: { infoTooltip },
    mixins: [validate],
    data() {
        return {};
    },
    created() {
        if(!this.model[this.fieldName])
            this.$set(this.model, this.fieldName, []);
    },
    methods: {
    },
};
</script>

<style lang="scss">
    .v-custom-contact-event {
        &.input-form {
            .v-input__slot {
                background-color: inherit !important;
            }
        }
    }
</style>