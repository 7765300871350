import store from '../../store'
import GenericDataService from '@/services/GenericDataService'

export default [
    {
        path: 'facturation',
        meta: { titleDocument: "facturation" },
        redirect: { name: 'Factures' },
        component: function () {
            return import(/* webpackChunkName: "Facturation" */ /* webpackPrefetch: true */'../../modules/facturation/views/Facturation.vue')
        },
        beforeEnter: (to, from, next) => {
            GenericDataService.getData('/menu/getsubmenu?module=facturation').then((response) => {
                let submenu = response.data.data;
                store.dispatch('base/SET_SUB_MENU', submenu)
                next();
            })
        },
        children: [
            {
                path: 'factures',
                name: 'Factures',
                meta: { title: 'Factures', titleDocument: "factures", rights: ["facturation/menu"] },
                component: function () {
                    return import(/* webpackChunkName: "Factures" */ /* webpackPrefetch: true */'../../modules/facturation/views/Factures.vue')
                },
                beforeEnter: (to, from, next) => {
                    GenericDataService.getData('/menu/getsubmenu?module=facturation').then((response) => {
                        let submenu = response.data.data;
                        store.dispatch('base/SET_SUB_MENU', submenu)
                        next();
                    })
                },
            },
            {
                path: 'suiviconso',
                name: 'SuiviConso',
                meta: { title: 'suiviConso', titleDocument: "suiviConso", rights: ["facturation/menu"] },
                component: function () {
                    return import(/* webpackChunkName: "SuiviConso" */ /* webpackPrefetch: true */'../../modules/facturation/views/SuiviConso.vue')
                },
            }
        ]
    }
]