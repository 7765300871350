<template>
    <v-dialog
    :retain-focus="false"
    no-click-animation
    :width="1200"
    transition="fade-transition"
    scrollable
    persistent
    @keydown.esc="$emit('cancel')"
    v-model="dialog"
    >
        <v-card tile>
            <v-toolbar dark flat class="bg-gradient" height="64">
                <v-btn x-large depressed color="transparent" class="ml-2" @click="$emit('cancel')"><v-icon dark small class="mr-2" >$prev</v-icon> {{ $t('back') }}</v-btn>
                <v-spacer></v-spacer>
                <v-btn depressed outlined class="mr-3" v-if="appDetails && appDetails.activeApp" @click="connecterApp()">Configurer l'application</v-btn>
                <v-btn depressed outlined class="mr-3" v-else @click="connecterApp()">Connecter l'application</v-btn>
            </v-toolbar>
            <v-card-text class="py-4" v-html="appDetails.descriptif">
            </v-card-text>
           
            <v-dialog eager v-model="iframeDialog" width="900">
                    <v-toolbar dark flat class="bg-gradient" height="64">
                        <v-btn x-large depressed color="transparent" class="ml-2" @click="iframeDialog = false"><v-icon dark small class="mr-2" >$prev</v-icon> {{ $t('back') }}</v-btn>
                        <v-spacer></v-spacer>
                    </v-toolbar>
                    <div id="iframe_container"></div>
                    <div id="form_uyl"></div> 
            </v-dialog>
        </v-card>
    </v-dialog>
</template>


<script>
import GenericDataService from '../../../services/GenericDataService';

export default {
    name: 'appListDialog',
    props: {
        dialog: Boolean,
        appDetails: Object
    },
    components: {},
    data() {
        return {
            dialogConfirm: false,
            iframeUrl : "",
            iframeDialog: false
        }
    },
    created() {
        window.addEventListener('message', this.receiveMessage, false);
    },
    methods: {
        receiveMessage(e){
            if(e.data == 'appConnected'){
                setTimeout(() => {
                    this.iframeDialog = false;
                }, 700);
            }
        },
        connecterApp() {
            this.dialogConfirm = false;

            let payload = {
                app_ref: this.appDetails.app_ref
            }
            GenericDataService.postData('/externalapp/initApp', payload).then((response) => {
                console.log(response.data.data.url)
                this.iframeUrl = response.data.data.url;
                let params = response.data.data.payload;
                var parentElm = document.getElementById('form_uyl');
                var iframeElm = document.getElementById('iframe_container');
                iframeElm.innerHTML = '';
                var form, input, frame;

                frame = document.createElement("iframe");
                frame.name = 'uyl_frame';
                frame.id = 'uyl_frame';
                frame.width = '100%';
                frame.height = '700';
                frame.src = this.iframeUrl;

                iframeElm.appendChild(frame);

                form = document.createElement("form");
                form.action = this.iframeUrl;
                form.method = "post";
                form.target = "uyl_frame";
                Object.keys(params).forEach(function (elm) {
                    console.log('elm: ', elm, params[elm]);
                    input = document.createElement("input");
                    input.name = elm;
                    input.value = params[elm];
                    input.type = "hidden";
                    form.appendChild(input);
                })
                parentElm.appendChild(form);
                form.submit();
                setTimeout(() => {
                    this.iframeDialog = true;
                }, 500);
                //this.$emit('cancel');
            })
        }
    }
}
</script>

<style lang="scss">
iframe {
    border:0;
}
.logoApp {
    margin: 0;
    border-radius: 100px;
    width: 80px;
}

.bulletPointHeader {
    margin-bottom: 4px !important;
}

.headerDescriptif {
    display: flex;
    align-items: center;
}
</style>