<template>
    <div>
        <div id="appTopol" style="position: absolute; width: 100%; height: calc( 100% - 95px); left:0;"></div>
        <MediathequeDialogComponent v-model="fileUrl" :openDialogMediatheque="dialogMediatheque" @close="closeDialogMediatheque" :prefilters="prefilters" :availableFilters="availableFilters" :upload="true" :returnUrl="true" />
        <v-dialog class="rounded-lg" persistent width="700" v-model="saveBlockDialog">
            <v-card>
                <v-toolbar tile flat dark  class="bg-gradient" >
                    <div style="display:flex; width:100%; align-items:center; justify-content:space-between;">
                        <div>
                            <v-toolbar-title>{{$t('saveBlock')}}</v-toolbar-title>
                        </div>
                        <div>
                            <v-btn outlined dark depressed @click.native="closeSaveConfirm">{{$t('cancel')}}</v-btn>
                            <v-btn outlined dark depressed @click="confirmSaveAction" class="ml-3">{{$t('confirm')}}</v-btn>
                        </div>
                    </div> 
                </v-toolbar>
                <v-card-text class="mt-4">
                    <v-text-field hide-details v-model="saveBlockName" solo flat class="input-form required" :label="$t('blockName')"></v-text-field>
                    <p class="error--text" v-if="errorName != ''">{{ errorName }}</p>
                </v-card-text>
            </v-card>
        </v-dialog>
        <v-dialog class="rounded-lg" persistent width="700" v-model="editBlockDialog">
            <v-card>
                <v-toolbar tile flat dark  class="bg-gradient" >
                    <div style="display:flex; width:100%; align-items:center; justify-content:space-between;">
                        <div>
                            <v-toolbar-title>{{$t('editNameBlock')}}</v-toolbar-title>
                        </div>
                        <div>
                            <v-btn outlined dark depressed @click.native="closeEditConfirm">{{$t('cancel')}}</v-btn>
                            <v-btn outlined dark depressed @click="confirmEditAction" class="ml-3">{{$t('confirm')}}</v-btn>
                        </div>
                    </div> 
                </v-toolbar>
                <v-card-text class="mt-4">
                    <v-text-field hide-details v-model="editBlockName" solo flat class="input-form required" :label="$t('blockName')"></v-text-field>
                    <p class="error--text" v-if="errorName != ''">{{ errorName }}</p>
                </v-card-text>
            </v-card>
        </v-dialog>
        <v-dialog class="rounded-lg" persistent width="700" v-model="deleteConfirmDialog">
            <v-card>
                <v-toolbar tile flat dark  class="bg-gradient" >
                    <div style="display:flex; width:100%; align-items:center; justify-content:space-between;">
                        <div>
                            <v-toolbar-title>{{$t('deleteBlockConfirmation')}}</v-toolbar-title>
                        </div>
                        <div>
                            <v-btn outlined dark depressed @click.native="deleteConfirmDialog = false">{{$t('cancel')}}</v-btn>
                            <v-btn outlined dark depressed @click="confirmDeleteAction" class="ml-3">{{$t('confirm')}}</v-btn>
                        </div>
                    </div> 
                </v-toolbar>
                <v-card-text class="mt-4">
                    {{ $t('Areyousuretodeleteblock') }}
                </v-card-text>
            </v-card>
        </v-dialog>
    </div>
</template>
<script>
import MediathequeDialogComponent from '@/components/dialogs/MediathequeDialogComponent'
import GenericDataService from '@/services/GenericDataService'

// Plugin Settings qui bougeront et qui devront etre dynamique (pour les merge tags par exemple : définition des variables)
const TOPOL_OPTIONS = {
    id: "#appTopol",
    authorize: {
        apiKey: "aENJaw1tAld461ePzU9MgcFZeU0gjgagt6GlK13N9ZYqXNdVyIR24z3FFulT",
        userId: "UserID",
    },
    language: "fr",
    savedBlocks: [],
    theme: {
        preset: 'light',
        colors: {
            primary: '#000',
            active: '#000',
            "c-success": '#09577d',
            "success-light": '#09577d',
            "active-light": '#000',
        }
    },
    customFileManager: true,
    // smartMergeTags: {
    //     //enabled: true,
    //     syntax: {
    //         start: "{{",
    //         end: "}}"
    //     }
    // },
    
    mergeTags: [],
    topBarOptions: ["undoRedo", "changePreview", "previewSize", "save", "saveAndClose"],
    premadeBlocks: [{
        name: 'Liens',
        blocks: [
            {
                name: "Lien de désinscription",
                definition:[
                    {
                        "tagName": "mj-section",
                        "attributes": {
                            "full-width": false,
                            "padding": "9px 0px 9px 0px"
                        },
                        "children": [
                            {
                                "tagName": "mj-column",
                                "attributes": {
                                    "width": "100%",
                                    "vertical-align": "top"
                                },
                                "children": [
                                    {
                                        "tagName": "mj-text",
                                        "attributes": {
                                            "align": "left",
                                            "padding": "15px 15px 15px 15px",
                                            "line-height": 1.5,
                                            "containerWidth": 600
                                        },
                                        "uid": "-aUZXKZ35",
                                        "content": "<p style=\"text-align: center;\" id=\"unsubscribeLink\"><span style=\"text-decoration: underline;\"><a href=\"{{unsubscribe.link}}\" target=\"_blank\" rel=\"noopener\">Se désinscrire<\/a><\/span><\/p>"
                                    }
                                ],
                                "uid": "HJQ8ytZzW"
                            }
                        ],
                        "layout": 1,
                        "backgroundColor": null,
                        "backgroundImage": null,
                        "paddingTop": 0,
                        "paddingBottom": 0,
                        "paddingLeft": 0,
                        "paddingRight": 0,
                        "uid": "Byggju-zb"
                    }
                ]
            },
            {
                name: "Lien de visualisation en ligne",
                definition:[
                    {
                        "tagName": "mj-section",
                        "attributes": {
                            "full-width": false,
                            "padding": "9px 0px 9px 0px"
                        },
                        "children": [
                            {
                                "tagName": "mj-column",
                                "attributes": {
                                    "width": "100%",
                                    "vertical-align": "top"
                                },
                                "children": [
                                    {
                                        "tagName": "mj-text",
                                        "attributes": {
                                            "align": "left",
                                            "padding": "15px 15px 15px 15px",
                                            "line-height": 1.5,
                                            "containerWidth": 600
                                        },
                                        "uid": "-aUZXKZ36",
                                        "content": "<p style=\"text-align: center;\" id=\"viewingLink\"><span style=\"text-decoration: underline;\"><a href=\"%%VIEWINGLINK%%\" target=\"_blank\" rel=\"noopener\">Voir la version en ligne<\/a><\/span><\/p>"
                                    }
                                ],
                                "uid": "HJQ8ytZzX"
                            }
                        ],
                        "layout": 1,
                        "backgroundColor": null,
                        "backgroundImage": null,
                        "paddingTop": 0,
                        "paddingBottom": 0,
                        "paddingLeft": 0,
                        "paddingRight": 0,
                        "uid": "Byggju-zd"
                    }
                ]
            }
        ]
    }],
    callbacks: {}
};

export default {
    name:"TemplateCreateEmail",
    props: ["templateId", "templateDuplicatedId"],
    components:{ MediathequeDialogComponent },
    data() {
        return {
            dialogMediatheque: false,
            fileUrl: "",
            prefilters: {
                "utilisation": "template_email"
            },
            availableFilters: [],
            blockToSave:{},
            saveBlockDialog: false,
            saveBlockName: "",
            errorName: "",
            editBlockName: "",
            editBlockDialog: false,
            editId: 0,
            deleteConfirmDialog: false,
            deleteId:0,
            customBlocks:[]
        }
    },
    created() {
        // let vm = this;

        // if(this.templateId){
        //     var idTemplate = this.templateId
        // } else {
        //     var idTemplate = vm.$route.params.id
        // }

        // GenericDataService.getData("/template/getAvailableVariables?template_id=" + idTemplate).then((response) => {
        //     TOPOL_OPTIONS.mergeTags = this.tradIfNeeded(response.data.data);
            
        //     TOPOL_OPTIONS.callbacks['onOpenFileManager'] = function() {
        //         vm.dialogMediatheque = true;
        //     };

        //     TOPOL_OPTIONS.callbacks['onInit'] = function() {
        //         vm.$store.dispatch("SET_DATA", {
        //             url: '/template/' + (vm.templateDuplicatedId ? vm.templateDuplicatedId : idTemplate) + '/get',
        //             mutation: 'template/setTemplateData'
        //         }).then((e) => {
        //             if(vm.template.template && vm.template.template.json)
        //                 TopolPlugin.load(JSON.stringify(vm.template.template.json));
        //         });
        //     };

        //     TOPOL_OPTIONS.callbacks['onSaveAndClose'] = function(json, html) {
        //         vm.saveTemplate(json, html, true);
        //     };

        //     TOPOL_OPTIONS.callbacks['onSave'] = function(json, html) {
        //         vm.saveTemplate(json, html);
        //     };

        //     TopolPlugin.init(TOPOL_OPTIONS);
        // });
    },
    computed: {
        template: function () {
            return this.$store.state.template.template
        },
    },
    mounted() {
        let vm = this;

        if(this.templateId){
            var idTemplate = this.templateId
        } else {
            var idTemplate = vm.$route.params.id
        }
        GenericDataService.getData('/template/getCustomBlocks').then((response) => {
            this.customBlocks = response.data.data
            TOPOL_OPTIONS.savedBlocks = response.data.data 
        })
        GenericDataService.getData("/template/getAvailableVariables?template_id=" + idTemplate).then((response) => {
            TOPOL_OPTIONS.mergeTags = this.tradIfNeeded(response.data.data);
            
            TOPOL_OPTIONS.callbacks['onOpenFileManager'] = function() {
                vm.dialogMediatheque = true;
            };

            TOPOL_OPTIONS.callbacks['onInit'] = function() {
                vm.$store.dispatch("SET_DATA", {
                    url: '/template/' + (vm.templateDuplicatedId ? vm.templateDuplicatedId : idTemplate) + '/get',
                    mutation: 'template/setTemplateData'
                }).then((e) => {
                    if(vm.template.template && vm.template.template.json)
                        TopolPlugin.load(JSON.stringify(vm.template.template.json));
                });
            };

            TOPOL_OPTIONS.callbacks['onSaveAndClose'] = function(json, html) {
                vm.saveTemplate(json, html, true);
            };

            TOPOL_OPTIONS.callbacks['onSave'] = function(json, html) {
                vm.saveTemplate(json, html);
            };

            TOPOL_OPTIONS.callbacks['onBlockSave'] = function(json) {
                vm.saveCustomBlock(json);
            };
            TOPOL_OPTIONS.callbacks['onBlockRemove'] = function(id) {
                vm.deleteCustomBlock(id);
            };
            TOPOL_OPTIONS.callbacks['onBlockEdit'] = function(id) {
                vm.editCustomBlock(id);
            };
            if(this.$store.state.auth.currentUser.accountParameters && this.$store.state.auth.currentUser.accountParameters.rcu){
                TOPOL_OPTIONS.api = {
                    PRODUCTS: process.env.VUE_APP_API_URL+"/entity/getStockForTOPOL?token="+this.$store.state.auth.idToken,
                    FEEDS: process.env.VUE_APP_API_URL+"/entity/getStockFeed?token="+this.$store.state.auth.idToken,
                }
            }
            
            TopolPlugin.init(TOPOL_OPTIONS);
           
            
        });
        
    },
    destroyed() {
        TopolPlugin.destroy()
    },
    watch: {
        fileUrl (newFileUrl) {
            TopolPlugin.chooseFile(newFileUrl);
        }
    },
    methods: {
        confirmSaveAction(){
            if(this.saveBlockName != ""){
                this.errorName = "";
                let payload = this._.cloneDeep(this.blockToSave);
                payload.name = this.saveBlockName;

                GenericDataService.postData('template/saveCustomBlock', payload).then((response) => {
                    GenericDataService.getData('/template/getCustomBlocks').then((response) => {
                        this.customBlocks = response.data.data
                        TopolPlugin.setSavedBlocks(response.data.data)
                        this.closeSaveConfirm();
                    })
                    
                })
                
            } else {
                this.errorName = "errorNameRequired"
            } 
        },
        closeSaveConfirm(){
            this.blockToSave = {};
            this.saveBlockName = "";
            this.saveBlockDialog = false;
        },
        saveCustomBlock(json){
            this.blockToSave = json;
            this.saveBlockDialog = true;
        },
        deleteCustomBlock(id){
            this.deleteId = id
            this.deleteConfirmDialog = true
        },
        confirmDeleteAction(){
            GenericDataService.postData('template/deleteCustomBlock?id='+this.deleteId).then((response) => {
                GenericDataService.getData('/template/getCustomBlocks').then((response) => {
                    this.customBlocks = response.data.data
                    TopolPlugin.setSavedBlocks(response.data.data);
                    this.deleteConfirmDialog = false
                })
            })
        },
        closeEditConfirm(){
            this.blockToSave = {};
            this.editBlockName = "";
            this.editId = 0;
            this.editBlockDialog = false;
        },
        confirmEditAction(){
            if(this.editBlockName != ""){
                this.errorName = "";
                let payload = {};
                payload.id = this.editId;
                payload.name = this.editBlockName;

                GenericDataService.postData('template/editCustomBlock', payload).then((response) => {
                    GenericDataService.getData('/template/getCustomBlocks').then((response) => {
                        this.customBlocks = response.data.data
                        TopolPlugin.setSavedBlocks(response.data.data);
                        this.closeEditConfirm();
                    })
                    
                })
                
            } else {
                this.errorName = "errorNameRequired"
            }  
        },
        editCustomBlock(id){
            let block = this.customBlocks.find((e) => e.id == id);
            this.editBlockName = block.name;
            this.editId = id;
            this.editBlockDialog = true;
        },
        tradIfNeeded(data){
            data.forEach(element => {
                if(element.to_trad){
                    if(element.hasOwnProperty('name'))
                        element.name = this.$t(element.name)
                    else
                        element.text = this.$t(element.text)
                }
                if(element.hasOwnProperty('items'))
                   element.items = this.tradIfNeeded(element.items)
            });
            return data
        },
        closeDialogMediatheque: function(){
            this.dialogMediatheque = false
        },
        saveTemplate: function(json, html, goToList = false){
            if(this.templateId){
                var idTemplate = this.templateId
            } else {
                var idTemplate = this.$route.params.id
            }

            let payload = {
                id: idTemplate,
                template: {
                    json: json,
                    html: html
                }
            }

            if(this.$route.params && this.$route.params.operation_id && this.$route.params.retroplanning_id){
                GenericDataService.postData("/template/set?retroplanning_id="+this.$route.params.retroplanning_id, payload).then((response) => {
                    if(goToList && response.data.success && response.data.data && response.data.data.id){
                        this.$router.push({name:"OperationCampaignDetailTemplate", params:{id: this.$route.params.retroplanning_id, operation_id: this.$route.params.operation_id}});
                    }
                })

            } else {
                GenericDataService.postData("/template/set", payload).then((response) => {
                    if(goToList && response.data.success && response.data.data && response.data.data.id && this.$route.params.id && !this.templateId){
                        this.$router.push({name:"Templates", params:{id: response.data.data.id}});
                    } else { //pas d'id dans la route donc création depuis opération stepper
                        this.$emit('refreshTemplates')
                    }

                });
            }

           
        }
    },
}
</script>

<style lang="scss">
    .scroll-area {
        .menu-header {
            display:none !important;
        }
    }
</style>
