<template>
    <v-container fluid class="pa-6">
        <DatatableV2 :displayTitle="false" :flat="false" :url="apiUrl" />
    </v-container>
</template>

<script>
import GenericDataService from '../../../services/GenericDataService';
import DatatableV2 from '@/components/DatatableV2';

export default {
    name: "Factures",
    components: {
        DatatableV2
    },
    data() {
        return {
            apiUrl: "/facturation/getFactureList",
        }
    },
    methods: {
    },
    computed: {

    },
    mounted() {
    }
}
</script>