<template>
    <div class="mt-6 retroplanningCSS">
        <v-row v-if="$func.hasRight('retroplanning/add')">
            <v-col cols="12" class="py-0">
                <v-menu offset-y>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn  depressed  class="bg-gradient mb-4" v-bind="attrs" v-on="on"><v-icon small class="mr-2">$plus_l</v-icon>{{ $t('addStep') }}</v-btn>
                    </template>
                    <v-list v-for="(subTypes, type) in actions" :key="type">
                        <div class="pa-2 pl-4 listTitle">{{ $t(type) }}</div>
                        <v-list-item v-for="(subType) in subTypes" :key="subType" link dense @click="openForm(type, subType)">
                            <v-list-item-title>{{ $t(subType) }}</v-list-item-title>
                        </v-list-item>
                    </v-list>
                </v-menu>
            </v-col>
        </v-row>
        <v-row dense>
            <v-col cols="12" v-for="(step, stepIndex) in steps" :class="(step.type !== 'operationStart' && step.type !== 'operationEnd') ? 'colStep' : 'operationStep'" :key="'step_'+stepIndex">

                <v-card class="rpCard" v-if="(step.type !== 'operationStart' && step.type !== 'operationEnd')">
                    <v-row dense class="pa-0">
                        <v-col cols="6" class="nameStep">
                            <v-btn outlined fab small class="rpIcon ml-3" color="primary">
                                <v-icon small>{{step.settings.icon}}</v-icon>
                            </v-btn>
                            <div class="ml-3">
                                <div class="dateElement" v-if="step.config.date_start">
                                    {{parseFullDateToFr(step.config.date_start)}}
                                    <span v-if="step.config.date_end"> - {{parseFullDateToFr(step.config.date_end)}}</span>
                                </div>
                                <h3 class="rpName" v-if="step.config.name">{{step.config.name}}</h3>
                                <h3 class="rpName" v-else> Pas de nom</h3>
                            </div>

                            <v-spacer></v-spacer>

                            <div v-if="step.config && !step.config.main_contact && (step.config.type === 'MISSION' || (step.config.type === 'CAMP_CALL' && step.config.prestataire_type === 'CALL'))" class="mr-3">
                                <v-tooltip left>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-icon color="warning" v-bind="attrs" v-on="on">$usercross_d</v-icon> 
                                    </template>
                                    <span>{{$t('mainContactEmpty')}} </span>
                                </v-tooltip>
                            </div>

                            <div v-if="step.config && step.config.prestataire && step.config.prestataire.showLimitDateProvider" class="mr-3">
                                <v-tooltip left>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-icon color="warning" v-bind="attrs" v-on="on">$warning_d</v-icon> 
                                    </template>
                                    <span>{{step.config.prestataire.showLimitDateProvider}} {{$t('days_delay_for_provider')}} </span>
                                </v-tooltip>
                            </div>
                        </v-col>

                        <v-col cols="6" class="configStep">
                            <div class="configContent col-10">
                                <p v-if="step.config.prevision_limit && step.config.sending_vol">{{$t('limited_sending_number')}} : <b>{{step.config.sending_vol}}</b></p>
                                <p v-if="step.config.segmentation">
                                    {{$t('segmentation')}} :
                                    <span v-if="step.config.segmentation.name"><b>{{step.config.segmentation.name}}</b></span>
                                    <span v-else><b>{{$t(step.config.segmentation)}}</b></span>
                                </p>
                                <p v-if="step.config.place">{{$t('place')}} : <b>{{step.config.place.text }}</b></p>
                                <!-- <p v-if="step.config.prestataire_type && step.config.prestataire_type === 'VENDORS'">{{$t('prestataire')}} : <b>{{$t('vendors')}}</b></p>
                                <p v-if="step.config.prestataire">{{$t('prestataire')}} : <b>{{step.config.prestataire.text}}</b></p> -->
                                <p v-if="step.config.main_contact && (step.config.type === 'MISSION' || (step.config.type === 'CAMP_CALL' && step.config.prestataire_type === 'CALL'))">{{$t('main_contact')}} : <b>{{step.config.main_contact.text}}</b></p>
                                <p v-if="step.config.coach_choice">{{$t('coach_choice')}} :<b> {{ $t(step.config.coach_choice) }}</b></p>
                                <p v-if="step.config.coach_selection">
                                    {{$t('coach_selected')}} : <b><span v-for="(coach, index) in step.config.coach_selection">{{coach.text}} <span v-if="index != (step.config.coach_selection.length - 1)">/ </span> </span></b>
                                </p>
                                <p v-if="step.config.coach_selection_mission">{{$t('mission_selected')}} : <b>{{ step.config.coach_selection_mission.text }}</b></p>
                                <p v-if="step.config.staff_qty && (!step.config.hasOwnProperty('coach_choice') || step.config.coach_choice == 'to_select')">{{$t('staff_qty')}} : <b>{{step.config.staff_qty}}</b></p>
                                <p v-if="step.config.template_cat">{{$t('template_cat')}} : <b>{{step.config.template_cat.text}}</b></p>
                                <p v-if="step.config.infomediaire">{{$t('infomediaire')}} : <b>{{step.config.infomediaire.text}}</b></p>
                                <p v-if="step.config.box">{{$t('boxType')}} : <b>{{step.config.box.text}}</b></p>
                                <p v-if="step.config.automatic_sending">{{$t('automatic_sending')}} : <b>{{step.config.automatic_sending == true ? $t('yes') : $t('no')}}</b></p>
                                <p v-if="step.config.managing_type">{{$t('type')}} : <b>{{$t(step.config.managing_type) }}</b></p>
                                <p v-if="step.config.description">{{$t('description')}} : <b>{{ step.config.description }}</b></p>
                                <p v-if="(step.config.sousType == 'missionCOACH' || step.config.sousType == 'missionCOACHtel') && (step.config.coach_choice == 'select_now' || (step.config.coach_choice == 'sameAsOther' && linkRPhasCoach(step.config.coach_selection_mission.value)) || (step.config.coach_choice =='to_select'))">
                                    <span v-if="step.status === 'PROGRAMMED' && step.config.programmed_date">{{ $t('mission_order') }} : <b>{{ $t('sent_on') + ' ' + parseFullDateToFr(step.config.programmed_date, false) }}</b></span>
                                    <span v-else>{{ $t('mission_order') }} : <b>{{ $t('not_sent') }}</b></span>
                                </p>
                            </div>
                            <div>
                                <span v-if="step.config.sousType == 'missionCOACH' || step.config.sousType == 'missionCOACHtel'">
                                    <span v-if="step.config.coach_choice == 'select_now' || (step.config.coach_choice == 'sameAsOther' && linkRPhasCoach(step.config.coach_selection_mission.value)) || (step.config.coach_choice =='to_select')">
                                        <!-- <v-btn v-if="$func.hasRight('retroplanning/add') && (!step.status || step.status == 'DRAFT')" disabled depressed text class="square"><v-icon small color="darkgrey">$bell_plus_d</v-icon></v-btn> -->
                                        <v-btn v-if="$func.hasRight('retroplanning/add') && step.status === 'PROGRAMMED'" disabled depressed text class="square"><v-icon small color="success">$bell_on_d</v-icon></v-btn>
                                    </span>
                                </span>
                                <span v-else-if="step.config.prestataire && ((step.config.type === 'MISSION'))">
                                <!-- <span v-else-if="step.config.prestataire && ((step.config.type === 'MISSION') || (step.config.type === 'CAMP_CALL' && step.config.prestataire_type === 'CALL'))"> -->
                                    <v-tooltip v-if="$func.hasRight('retroplanning/add') && (!step.status || step.status == 'DRAFT')" bottom>
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-btn v-bind="attrs" v-on="on" depressed text class="square" @click="notifyProvider(step.id)"><v-icon small>$bell_plus_d</v-icon></v-btn>
                                        </template>
                                        <span>{{ $t('notifyProviderTooltip') }}</span>
                                    </v-tooltip>
                                    <v-btn v-else-if="$func.hasRight('retroplanning/add') && step.status === 'PROVIDER_EXPECTATION'" disabled depressed text class="square"><v-icon small color="success">$bell_on_d</v-icon></v-btn>
                                </span>
                                <v-btn v-if="$func.hasRight('retroplanning/update')" depressed text class="square" @click="editItem(step.id)"><v-icon small>$pencil_d</v-icon></v-btn>
                                <v-btn v-if="$func.hasRight('retroplanning/delete')" depressed text class="square" @click="getDeleteConfirmationItem(step.id)"><v-icon small color="error">$trash_d</v-icon></v-btn>
                            </div>
                        </v-col>
                    </v-row>
                </v-card>

                <v-card depressed rounded="lg" class="operation" v-else>
                    <v-img
                            :src= "step.type === 'operationStart' ? publicPath+'img/fusee.png' : publicPath+'img/drapeau.png'"
                            height="70"
                            max-width="80"
                            contain
                    ></v-img>

                    <div class="ml-4">
                        <div class="rpName">{{ $t(step.config.name) }}</div>
                        {{ parseFullDateToFr(step.config.date_start) }}
                    </div>

                </v-card>

            </v-col>
        </v-row>
        <v-dialog width="600" v-model="dialogConfirm">
            <v-card>
                <v-toolbar flat dark color="primary" class="toolbar bg-gradient">
                    <v-toolbar-title>{{$t('confirmDeleteLabel')}}</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-btn depressed outlined color="white" class="mr-3" @click="closeDialog">
                        {{$t('cancelLabel')}}
                    </v-btn>
                    <v-btn depressed outlined color="white" @click="deleteItem">
                        {{$t('confirmLabel')}}
                    </v-btn>
                </v-toolbar>
                <v-card-text class="pt-3">
                    {{$t('confirmDeleteRetroplanningStepText')}}
                </v-card-text>
            </v-card>
        </v-dialog>
        <FormDialogComponent
        v-if="form"
        ref="componentForm"
        :form="form"
        :action="'SET_DATA'"
        />
        <Loader :flat="true" v-if="loading" style="min-height:500px;"/>
    </div>
</template>
<script>
import Loader from '@/components/Loader'
import GenericDataService from '@/services/GenericDataService'
import FormDialogComponent from "@/components/dialogs/FormDialogComponent";
import getForm from "@/mixins/mixins";
import dateManipulation from '@/mixins/dateManipulation'

export default {
    name:"retroplanningStepForm",
    props: ["operationId", "loadData"],
    components:{FormDialogComponent, Loader},
    mixins: [getForm, dateManipulation],
    data() {
        return {
            publicPath: process.env.BASE_URL,
            dialogConfirm: false,
            itemToDelete: "",
            form: null,
            steps: [],
            actions: [],
            loading:false,
        }
    },
    destroyed() {
        this.steps = []
    },
    watch: {
        loadData: {
            immediate:true,
            handler(val){
                if(val){
                    this.loading = true
                    GenericDataService.getData("/operation/getRetroplanningTypes").then((response) => {
                        this.actions = response.data.data;
                        GenericDataService.getData('/operation/'+this.operationId+'/getRetroplanning').then((response) => {
                            this.steps = response.data.data;
                            this.loading = false
                        })
                    })
                }
            }
        },
        steps(newSteps) {
            if(this.steps.length > 0) {
                
                let stepCampOutArray = this.steps.filter((e) => e.type.match(/^retroplanning.CAMP_OUT/))
                let stepCampCallArray = this.steps.filter((e) => e.type.match(/^retroplanning.CAMP_CALL/))
                let stepCampInArray = this.steps.filter((e) => e.type.match(/^retroplanning.CAMP_IN/))
                let stepMissionCoachArray = this.steps.filter((e) => e.type.match(/^retroplanning.MISSION.missionCOACH/))



                //si au moins une campagne sortante
                if(stepCampOutArray.length > 0 || stepCampCallArray.length > 0){
                    this.$emit('addStep', 'templates')
                    this.$emit('addStep', 'contactList')
                } else {
                    this.$emit('removeStep', 'templates')
                    this.$emit('removeStep', 'contactList')
                }

                if(stepMissionCoachArray.length > 0){
                    this.$emit('addStep', 'coachs')
                } else {
                    this.$emit('removeStep', 'coachs')
                }
                //si au moins une campagne sortante ou entrante
                // if(stepCampOutArray.length > 0 || stepCampInArray.length > 0){
                //     this.$emit('addStep', 'entryPoints')
                // } else {
                //     this.$emit('removeStep', 'entryPoints')
                // }

                this.steps.forEach(function(step) {
                    if(step.config && step.config.date_start && step.config.prestataire && step.config.prestataire.deadline_mission) {
                        let currentDate = new Date();
                        let dateLimitProvider = new Date(step.config.date_start);
                        dateLimitProvider.setDate(dateLimitProvider.getDate() - step.config.prestataire.deadline_mission);

                        if(currentDate > dateLimitProvider) {
                            step.config.prestataire.showLimitDateProvider = this.DaysBetween(dateLimitProvider, currentDate);
                        }
                    }
                }, this);
            } else {
                this.$emit('removeStep', 'template')
                this.$emit('removeStep', 'contactList')
            }
        },
    },
    methods: {
        customRefresh(response){
            if(response) {
                this.steps = response.data.data;
            }
        },
        editItem(idItem){
            this.getForm('/operation/' + this.operationId + '/getFormRetroplanning?id=' + idItem)
        },
        openForm(type, subType){
            this.getForm('/operation/' + this.operationId + '/getFormRetroplanning?type=' + type + "&subType=" + subType+ "&date="+new Date());
        },
        closeDialog(){
            this.itemToDelete = ""
            this.dialogConfirm = false
        },
        deleteItem(){
            GenericDataService.getData('/operation/'+this.operationId+'/deleteRetroplanning?id='+this.itemToDelete).then((response) => {
                this.steps = response.data.data;
                this.dialogConfirm = false
                this.itemToDelete = ""
            })
        },
        getDeleteConfirmationItem(idItem){
            this.itemToDelete = idItem
            this.dialogConfirm = true
        },
        removeLine(index) {
            this.steps.splice(index, 1)
        },
        notifyProvider(index) {
            GenericDataService.getData('/operation/' + this.operationId + '/notifyProvider?retroplanning_id=' + index).then((response) => {
                GenericDataService.getData('/operation/' + this.operationId + '/getRetroplanning').then((response) => {
                    this.steps = response.data.data;
                    this.$store.dispatch('ADD_SNACK', { message : this.$t('successActionConfirmed') }).then((e) => { this.$store.dispatch("REMOVE_SNACK") });
                });
            });
        },
        notifyAndConfirmCoach(index){
            GenericDataService.getData('/operation/' + this.operationId + '/notifyAndConfirmCoach?retroplanning_id=' + index).then((response) => {
                GenericDataService.getData('/operation/' + this.operationId + '/getRetroplanning').then((response) => {
                    this.steps = response.data.data;
                    this.$store.dispatch('ADD_SNACK', { message : this.$t('successActionConfirmed') }).then((e) => { this.$store.dispatch("REMOVE_SNACK") });
                });
            });
        },
        linkRPhasCoach(linkedRPID) {
            let linkedRP = this.steps.find((e) => e.id == linkedRPID);
            if(linkedRP && linkedRP.config && linkedRP.config.coach_choice && linkedRP.config.coach_choice == 'select_now') {
                return true;
            }
            return false;
        }
    },
}
</script>
<style lang="scss">
.retroplanningCSS{
    
    .popover {
        background-color: #ffffff;
        padding: 10px;
        color: var(--v-warning-base);
        font-weight: 800;
    }

    .rpCard {
        border-left: 8px solid var(--v-pink-base) !important;
        padding: 15px;
        display: flex;
        align-items: center;
        background-color: #ffffff;

        .rpNumber {
            width: 120px;
            text-align: center;
            font-weight: 700;
            color: var(--v-lightgrey-base);
            font-size: 80px;
            letter-spacing: -5px;
        }

        .rpIcon {
            border-width: 3px;
        }

        .rpName {
            font-weight: 800;
            color: #000000;
            font-size: 15px;
        }
    }

    .operation {
        color: white !important;
        background-color: var(--v-primary-base) !important;
        padding: 10px 30px;
        display: flex;
        align-items: center;

        .rpName {
            font-weight: 800;
            color: #FFFFFF;
            font-size: 20px;
            padding-bottom: 5px;
        }
    }

    .nameStep {
        display:flex;
        align-items:center;

    }

    .dateElement {
        color: var(--v-primary-base);
    }

    .configStep {
        display:flex;
        align-items: center;
        justify-content: space-between;
        border-left:1px solid rgba(0,0,0,0.12)
    }
    .configContent {
        padding-left:10px;
        p {
            margin-bottom:0;
        }
    }

}
</style>
